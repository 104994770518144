import { BilledItemObject } from '~/models/billingState';
import { AddressObject } from '~/models/masterdata/Address';
import { UserObject } from '~/models/masterdata/User';

import { parseDate } from '~/utils/dateUtils';
import ObjectUtils from '~/utils/objectUtils';

// Cache the delivery note rows to avoid re-calculating them on every render.
const deliveryNoteRowCache = new Map();

/**
 * Clears the cached delivery note rows.
 *
 * @function
 * @returns {void} No return value.
 */
export const clearDeliveryNoteRowCache = () => {
  deliveryNoteRowCache.clear();
};

/**
 * Maps the data of one delivery row to a structured format for display.
 *
 * @param {Object} deliveryNote - The delivery note object to map.
 * @return {Object} The mapped delivery row.
 */
export const mapDeliveryRow = (deliveryNote) => {
  const cacheKey = `${deliveryNote.id}-${deliveryNote.modifiedOn}`;

  if (deliveryNoteRowCache.has(cacheKey)) {
    return deliveryNoteRowCache.get(cacheKey);
  }

  // Set the user of the process state by the latest process state.
  const processStateChangeUser =
    [
      UserObject.getName(deliveryNote.processStateChangeUser.cancelled),
      UserObject.getName(deliveryNote.processStateChangeUser.delivered),
      UserObject.getName(deliveryNote.processStateChangeUser.arrived),
      UserObject.getName(deliveryNote.processStateChangeUser.inTransport),
      UserObject.getName(deliveryNote.processStateChangeUser.readyForOutput),
    ].find(Boolean) || '';

  const permittedToSitesNames = deliveryNote.permittedToSites.map(
    ({ name }) => name,
  );
  const permittedCostCentersNames = deliveryNote.permittedCostCenters.map(
    ({ name }) => name,
  );

  const userActionsRequestSignatures =
    deliveryNote.userActions.requestSignatures.map(({ userName }) => userName);
  const userActionsShareDeliveryNote =
    deliveryNote.userActions.shareDeliveryNote.map(({ userName }) => userName);
  const deliveryNoteArticles = deliveryNote.articles.map(
    ({ customData, number, type }) => ({
      customData: ObjectUtils.entries(customData),
      number,
      type, // FIXME: this unnecessary mapping makes it MUCH harder to work with the data
    }),
  );
  const customData = Object.fromEntries(
    Object.entries(deliveryNote.customData).map(([key, value]) => [
      key.slice(0, 36), // Old customField keys might consist of UUID + label; only get the UUID
      value,
    ]),
  );

  const row = {
    acceptState: deliveryNote.acceptState,
    articleNumbers: deliveryNoteArticles.map(({ number }) => number),
    articles: deliveryNoteArticles.map(({ type }) => type),
    buyer: deliveryNote.buyer.name,
    buyerId: deliveryNote.buyerId,
    buyerOrderReferences: deliveryNote.buyerOrderReferences,
    carrier: deliveryNote.carrier.name,
    carrierId: deliveryNote.carrierId,
    category: deliveryNote.getCategoryDescription(),
    comments: deliveryNote.comments.join(' | '),
    constructionComponents: deliveryNote.constructionComponents,
    constructionPlans: deliveryNote.constructionPlans,
    costCenters: deliveryNote.costCenters,
    createdOn: deliveryNote.createdOn,
    deliveryListActions: `${deliveryNote.processState};${deliveryNote.toSiteRecipient.name};${permittedToSitesNames.join(', ')};${permittedCostCentersNames.join(', ')};${JSON.stringify(deliveryNote.userActions)}`,
    deliveryNoteSharedWith: userActionsShareDeliveryNote.join(', '),
    deliveryType: deliveryNote.deliveryType,
    description: deliveryNote.deliveryDescription,
    dlnDate: parseDate(deliveryNote.dlnDate),
    fromSite: deliveryNote.fromSite.name,
    fromSiteIssuerAssignedId: deliveryNote.fromSite.issuerAssignedId,
    grossWeight: deliveryNote.grossWeight,
    grossWeightAmount: deliveryNote.grossWeightAmount,
    grossWeightUnit: deliveryNote.grossWeightUnit,
    id: deliveryNote.id,
    licensePlate: deliveryNote.carrierLicensePlate,
    mainArticleAmountUnit: deliveryNote.mainArticle.amount.unit,
    mainArticleAmountValue: deliveryNote.mainArticle.amount.value,
    mainArticleNumber: deliveryNote.mainArticle.number,
    mainArticleType: deliveryNote.mainArticle.type,
    modifiedOn: deliveryNote.modifiedOn,
    movementMeans: deliveryNote.movementMeans,
    number: deliveryNote.number,
    permittedCostCenterNames: permittedCostCentersNames,
    permittedCostCenters: deliveryNote.permittedCostCenters,
    permittedToSiteNames: permittedToSitesNames,
    permittedToSites: deliveryNote.permittedToSites,
    processState: deliveryNote.processState,
    processStateChangeUser,
    project: deliveryNote.project,
    recipient: deliveryNote.recipient.name,
    seller: deliveryNote.seller.name,
    sellerOrderReferences: deliveryNote.sellerOrderReferences,
    settledStatus: BilledItemObject.getSettledStatusDescription(
      deliveryNote.settledStatus,
    ),
    signaturesRequestedFrom: userActionsRequestSignatures.join(', '),
    sOus: deliveryNote.sOus,
    status: `${deliveryNote.processState};${deliveryNote.acceptState};${deliveryNote.combinedState};${deliveryNote.settledStatus}`,
    supplier: deliveryNote.supplier.name,
    tareWeight: deliveryNote.tareWeight,
    tareWeightAmount: deliveryNote.tareWeightAmount,
    tareWeightUnit: deliveryNote.tareWeightUnit,
    toSiteRecipient: deliveryNote.toSiteRecipient.name,
    toSiteSupplier: deliveryNote.toSiteSupplier.name,
    toSiteSupplierAddress: AddressObject.stringify(
      deliveryNote.toSiteSupplier.address,
    ),
    totalWeightGross: deliveryNote.totalWeightGross,
    totalWeightNet: deliveryNote.totalWeightNet,
    totalWeightTare: deliveryNote.totalWeightTare,
    trader: deliveryNote.trader.name,
    weight: deliveryNote.weight,
    weightAmount: deliveryNote.weightAmount,
    weightUnit: deliveryNote.weightUnit,
    ...customData,
  };

  for (const article of deliveryNoteArticles) {
    // Add custom data values on article level to row.
    if (article?.customData)
      for (const { key, value } of article?.customData ?? []) {
        row[key] ||= [];

        // Convert the custom data value to an array, so it can hold multiple values.
        if (!Array.isArray(row[key])) {
          row[key] = [row[key]];
        }

        if (!row[key].includes(value)) {
          row[key].push(value);
        }
      }
  }

  // Store the mapped row in the cache
  deliveryNoteRowCache.set(cacheKey, row);

  return row;
};
