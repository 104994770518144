import EnumValueNotFoundException from '~/errors/EnumValueNotFoundException';

import { joinComponents } from '~/utils/array';
import { Log } from '~/utils/logging';
import { isPlainObject } from '~/utils/object';
import { toCamelCase } from '~/utils/string';
import { getAbbreviatedUnit } from '~/utils/unit';
import UnitUtils from '~/utils/unitUtils';

import { AttachmentObject } from '../deliveries/attachmentUtils';
import { SignatureRolesObject } from '../masterdata/SignatureRoles';

import { AcceptStateCalculatorObject } from './acceptStateCalculatorUtils';
import { ACCEPT_ARTICLE_ACCEPT_STATUS } from './constants';
import { type AcceptItem, type PartialAcceptStatus } from './types';

export const AcceptArticleObject = {
  ACCEPT_STATUS: ACCEPT_ARTICLE_ACCEPT_STATUS,
  unit: undefined as string | undefined,

  /**
   * Creates a new AcceptArticleObject object.
   * @param acceptItem - The item data from the API.
   * @param chainId - The chain ID for attachments.
   */
  create(acceptItem: Partial<AcceptItem> = {}, chainId?: string) {
    const acceptStatus = acceptItem?.accept ?? undefined;
    const partialAcceptStatus = acceptItem?.partialAccept ?? undefined;
    const reason = acceptItem?.reason ?? undefined;
    const attachments =
      acceptItem?.attachments?.map((attachment) =>
        AttachmentObject.create(attachment.blob, chainId, attachment.type),
      ) ?? [];
    const acceptState = this.getAcceptState(acceptStatus, partialAcceptStatus);
    const documentLogisticsPackagePosition =
      this.getDocumentLogisticsPackagePosition(acceptItem?.path);
    const documentLineItemPosition = this.getDocumentLineItemPosition(
      acceptItem?.path,
    );

    return {
      ...this,
      acceptState,
      acceptStatus,
      attachments,
      documentLineItemPosition,
      documentLogisticsPackagePosition,
      partialAcceptStatus,
      reason,
    };
  },

  /**
   * Determines the accept state based on accept and partial accept status.
   * @param acceptStatus - The overall accept status.
   * @param partialAcceptStatus - The partial accept status.
   */
  getAcceptState(
    acceptStatus: string | undefined,
    partialAcceptStatus: PartialAcceptStatus,
  ) {
    if (acceptStatus) {
      return acceptStatus === this.ACCEPT_STATUS.VALID.KEY
        ? AcceptStateCalculatorObject.ACCEPT_STATE.APPROVED
        : AcceptStateCalculatorObject.ACCEPT_STATE.DECLINED;
    }

    if (!partialAcceptStatus) {
      return AcceptStateCalculatorObject.ACCEPT_STATE.OPEN;
    }

    return AcceptStateCalculatorObject.ACCEPT_STATE.DECLINED;
  },

  /**
   * Extracts the document log package position from a path array.
   * @param path - The path array.
   */
  getDocumentLogisticsPackagePosition(path?: string[]) {
    if (!path) {
      return null;
    }

    const camelCasePath = path.map((item) => toCamelCase(item));

    const index = camelCasePath.indexOf('logisticsPackage');

    return path[index + 1] ?? null;
  },

  /**
   * Extracts the document line item position from a path array.
   * @param path - The path array.
   */
  getDocumentLineItemPosition(path?: string[]) {
    if (!path) {
      return null;
    }

    const camelCasePath = path.map((item) => toCamelCase(item));

    const index = camelCasePath.indexOf('lineItem');

    return path[index + 1] ?? null;
  },

  /**
   * Retrieves the descriptive name of the current accept status.
   * @returns {string} - The descriptive name corresponding to the current accept status.
   * @throws {EnumValueNotFoundException} - If the accept status is invalid or not found in the constants.
   */
  getDescriptiveAcceptStatus() {
    const acceptStatusConst = Object.keys(this.ACCEPT_STATUS).find(
      (x) => this.ACCEPT_STATUS[x].KEY === this.ACCEPT_STATUS,
    );

    if (!acceptStatusConst) {
      throw new EnumValueNotFoundException(
        `Invalid accept status: ${this.ACCEPT_STATUS}`,
      );
    }

    return this.ACCEPT_STATUS[acceptStatusConst].DESCRIPTION;
  },

  /**
   * Sets the unit for the object.
   * @param {string} unit - The unit to be set.
   */
  setUnit(unit: string | undefined) {
    this.unit = unit;
  },
  /**
   * Converts partial accept statuses to a descriptive string.
   * @param partialAcceptStatus - The partial accept statuses.
   * @param unit - The unit for formatting values.
   */

  partialAcceptStatusToString(
    partialAcceptStatus: PartialAcceptStatus,
    unit: string | undefined,
  ) {
    if (!partialAcceptStatus || !isPlainObject(partialAcceptStatus)) {
      return '';
    }

    const items: string[] = [];
    for (const key of Object.keys(partialAcceptStatus)) {
      if (key === this.ACCEPT_STATUS.VALID.KEY) {
        continue;
      }

      const value = partialAcceptStatus[key];
      if (key === this.ACCEPT_STATUS.DELTA.KEY) {
        if (value < 0) {
          items.push(
            `${UnitUtils.formatValueUnitPairAsString_safe(
              -1 * value,
              unit,
              getAbbreviatedUnit,
            )} überschüssig`,
          );
        } else {
          items.push(
            `${UnitUtils.formatValueUnitPairAsString_safe(
              value,
              unit,
              getAbbreviatedUnit,
            )} fehlt`,
          );
        }

        continue;
      }

      const status = Object.keys(this.ACCEPT_STATUS).find(
        (statusKey) => key === this.ACCEPT_STATUS[statusKey].KEY,
      );
      if (!status) {
        Log.error(
          null,
          new EnumValueNotFoundException(
            `Invalid partial accept status: ${key}`,
          ),
        );

        continue;
      }

      items.push(
        `${UnitUtils.formatValueUnitPairAsString_safe(value, unit, getAbbreviatedUnit)} ${this.ACCEPT_STATUS[status].DESCRIPTION}`,
      );
    }

    return items.join(', ');
  },

  /**
   * Retrieves the acceptance article for a specific party based on provided positions.
   * @param {AcceptItem[]} acceptItems - An array of acceptance items.
   * @param {string} party - The party for which to retrieve the acceptance article (e.g., "recipient").
   * @param {number} articleDocumentLogisticsPackagePosition - The position of the document log package.
   * @param {number} articleDocumentLineItemPosition - The position of the document line item.
   * @returns {AcceptArticle} - The acceptance article for the specified party and positions.
   */
  getAcceptArticleOfParty(
    acceptItems: AcceptItem[],
    party: string,
    articleDocumentLogisticsPackagePosition: number,
    articleDocumentLineItemPosition: number,
  ) {
    if (party === SignatureRolesObject.SIGNATURE_ROLE.RECIPIENT.KEY) {
      return this.getAcceptArticleOfRecipient(
        acceptItems,
        articleDocumentLogisticsPackagePosition,
        articleDocumentLineItemPosition,
      );
    }

    const acceptArticles =
      acceptItems?.[party]?.map((acceptItem) => this.create(acceptItem)) ?? [];
    const acceptArticle = acceptArticles.find(
      (acceptArticle) =>
        acceptArticle.documentLogisticsPackagePosition ===
          articleDocumentLogisticsPackagePosition &&
        acceptArticle.documentLineItemPosition ===
          articleDocumentLineItemPosition,
    );

    return acceptArticle ?? this.create();
  },

  /**
   * Retrieves the acceptance article for the recipient based on provided positions.
   * Handles both legacy and current recipient articles.
   * @param {AcceptItem[]} acceptItems - An array of acceptance items.
   * @param {number} articleDocumentLogisticsPackagePosition - The position of the document log package.
   * @param {number} articleDocumentLineItemPosition - The position of the document line item.
   * @returns {AcceptArticle} - The acceptance article for the recipient at the specified positions.
   */
  getAcceptArticleOfRecipient(
    acceptItems: AcceptItem[],
    articleDocumentLogisticsPackagePosition: number,
    articleDocumentLineItemPosition: number,
  ) {
    const acceptArticlesRecipient =
      acceptItems?.[SignatureRolesObject.SIGNATURE_ROLE.RECIPIENT.KEY]?.map(
        (acceptItem) => this.create(acceptItem),
      ) ?? [];
    const acceptArticlesLegacyRecipient =
      acceptItems?.[
        SignatureRolesObject.SIGNATURE_ROLE.RECIPIENT.LEGACY_KEY
      ]?.map((acceptItem) => this.create(acceptItem)) ?? [];

    const acceptStateRecipient =
      AcceptStateCalculatorObject.calculateOverallAcceptStateFromArticles(
        acceptArticlesRecipient.map(
          (acceptArticle) => acceptArticle.acceptState,
        ),
      );
    const acceptStateLegacyRecipient =
      AcceptStateCalculatorObject.calculateOverallAcceptStateFromArticles(
        acceptArticlesLegacyRecipient.map(
          (acceptArticle) => acceptArticle.acceptState,
        ),
      );

    let acceptArticles = [];

    // If the accept state of recipient is open and the accept state of buyer isn't open,
    // we assume that this is a legacy dln where the recipient accept article has been written into buyer.
    // However, this assumption isn't 100% safe and can lead to wrong results, but it is currently the best approach to handle legacy dlns.
    if (
      acceptStateLegacyRecipient !==
        AcceptStateCalculatorObject.ACCEPT_STATE.OPEN &&
      acceptStateRecipient === AcceptStateCalculatorObject.ACCEPT_STATE.OPEN
    ) {
      acceptArticles = acceptArticlesLegacyRecipient;
    } else {
      acceptArticles = acceptArticlesRecipient;
    }

    const acceptArticle = acceptArticles.find(
      (acceptArticle) =>
        acceptArticle.documentLogisticsPackagePosition ===
          articleDocumentLogisticsPackagePosition &&
        acceptArticle.documentLineItemPosition ===
          articleDocumentLineItemPosition,
    );

    return acceptArticle ?? this.create();
  },
};
