// When extending the ROUTES, more specific routes must be defined below as when comparing the current URL with those routes,
// the Route object is iterated from top down and current URL <-> route are compared with .includes
import { FEATURE_FLAG } from './featureFlags';

export const ROUTE = {
  CONCRETE_DIARY: {
    FEATURE_FLAG: FEATURE_FLAG.CONCRETE_DIARY,
    PERMISSIONS: ['dln_in.'],
    ROUTE: '/concrete-diary',
  },
  CREATE_DELIVERY_NOTE: {
    FEATURE_FLAG: FEATURE_FLAG.CREATE_DELIVERY_NOTE,
    PERMISSIONS: ['dln_in.', 'dln_out.', 'dln_trans.'],
    ROUTE: '/create-delivery-note',
  },
  CREATE_SERVICE_NOTE: {
    FEATURE_FLAG: FEATURE_FLAG.SERVICE_NOTES,
    PERMISSIONS: ['dln_in.', 'dln_out.', 'dln_trans.'],
    ROUTE: '/create-service-note',
  },
  DASHBOARD: {
    PERMISSIONS: ['dln_in.', 'dln_out.', 'dln_trans.'],
    ROUTE: '/dashboard',
  },
  DATA_EXCHANGE: {
    ROUTE: '/data-exchange',
  },
  DELIVERIES: {
    PERMISSIONS: ['dln_in.', 'dln_out.', 'dln_trans.'],
    ROUTE: '/deliveries',
  },
  DELIVERY_NOTE: {
    MAIN_ROUTE: '/deliveries',
    PERMISSIONS: ['dln_in.', 'dln_out.', 'dln_trans.'],
    ROUTE: '/delivery-note',
  },
  DELIVERY_NOTE_DATA_QUALITY: {
    MAIN_ROUTE: '/deliveries',
    PERMISSIONS: ['dln_in.', 'dln_out.', 'dln_trans.'],
    ROUTE: '/delivery-note-data-quality',
  },
  HOME: {
    ROUTE: '/',
  },
  IDS_REDIRECT: {
    ROUTE: '/ids-redirect',
  },
  INCOMING_INVOICE: {
    DISABLING_FEATURE_FLAG: FEATURE_FLAG.DISABLE_INVOICES,
    MAIN_ROUTE: '/incoming-invoices',
    PERMISSIONS: ['invoice_in.'],
    ROUTE: '/incoming-invoices/invoice',
  },
  INCOMING_INVOICES: {
    DISABLING_FEATURE_FLAG: FEATURE_FLAG.DISABLE_INVOICES,
    PERMISSIONS: ['invoice_in.'],
    ROUTE: '/incoming-invoices',
  },
  INVOICE: {
    DISABLING_FEATURE_FLAG: FEATURE_FLAG.DISABLE_INVOICES,
    PERMISSIONS: ['invoice_in.', 'invoice_out.'],
    ROUTE: '/invoice-link',
  },
  OUTGOING_INVOICE: {
    DISABLING_FEATURE_FLAG: FEATURE_FLAG.DISABLE_INVOICES,
    MAIN_ROUTE: '/outgoing-invoices',
    PERMISSIONS: ['invoice_out.'],
    ROUTE: '/outgoing-invoices/invoice',
  },
  OUTGOING_INVOICES: {
    DISABLING_FEATURE_FLAG: FEATURE_FLAG.DISABLE_INVOICES,
    PERMISSIONS: ['invoice_out.'],
    ROUTE: '/outgoing-invoices',
  },
  SETTINGS: {
    ROUTE: '/settings',
  },
  SETTINGS_ARTICLES: {
    MAIN_ROUTE: '/settings',
    ONLY_ACCESS_BY_VESTIGAS_SUPPORT: true,
    PERMISSIONS: ['org_unit.'],
    RELATIVE: '/admin/article-master/articles',
    ROUTE: '/settings/admin/article-master/articles',
  },
  SETTINGS_CATEGORIES: {
    MAIN_ROUTE: '/settings',
    ONLY_ACCESS_BY_VESTIGAS_SUPPORT: true,
    PERMISSIONS: ['org_unit.'],
    RELATIVE: '/admin/article-master/categories',
    ROUTE: '/settings/admin/article-master/categories',
  },
  SETTINGS_COMPANY: {
    MAIN_ROUTE: '/settings',
    PERMISSIONS: ['company.'],
    RELATIVE: '/masterdata/company',
    ROUTE: '/settings/masterdata/company',
  },
  SETTINGS_COST_CENTER: {
    MAIN_ROUTE: '/settings',
    PERMISSIONS: ['accounting_reference.'],
    RELATIVE: '/masterdata/cost-center',
    ROUTE: '/settings/masterdata/cost-center',
  },
  SETTINGS_CUSTOM_FIELDS: {
    MAIN_ROUTE: '/settings',
    ONLY_ACCESS_BY_VESTIGAS_SUPPORT: true,
    PERMISSIONS: ['org_unit.'],
    RELATIVE: '/admin/custom-fields',
    ROUTE: '/settings/admin/custom-fields',
  },
  SETTINGS_CUSTOMER_SUBSCRIPTIONS: {
    FEATURE_FLAG: FEATURE_FLAG.CUSTOMER_SUBSCRIPTION,
    MAIN_ROUTE: '/settings',
    PERMISSIONS: ['org_unit.'],
    RELATIVE: '/admin/customer-subscriptions',
    ROUTE: '/settings/admin/customer-subscriptions',
  },
  SETTINGS_DATA_PROTECTION: {
    MAIN_ROUTE: '/settings',
    RELATIVE: '/data-protection',
    ROUTE: '/settings/data-protection',
  },
  SETTINGS_DATA_SUBSCRIPTIONS: {
    MAIN_ROUTE: '/settings',
    ONLY_ACCESS_BY_VESTIGAS_SUPPORT: true,
    PERMISSIONS: ['org_unit.'],
    RELATIVE: '/admin/data-subscriptions',
    ROUTE: '/settings/admin/data-subscriptions',
  },
  SETTINGS_DEMO: {
    FEATURE_FLAG: FEATURE_FLAG.CREATE_DEMO_DLNS,
    MAIN_ROUTE: '/settings',
    RELATIVE: '/demo',
    ROUTE: '/settings/demo',
  },
  SETTINGS_IMPRINT: {
    MAIN_ROUTE: '/settings',
    ONLY_ACCESS_BY_VESTIGAS_SUPPORT: true,
    RELATIVE: '/imprint',
    ROUTE: '/settings/imprint',
  },
  SETTINGS_INVOICE_CHECK_IGNORED_ARTICLES: {
    MAIN_ROUTE: '/settings',
    ONLY_ACCESS_BY_VESTIGAS_SUPPORT: true,
    PERMISSIONS: ['org_unit.'],
    RELATIVE: '/admin/invoice-check-ignored-articles',
    ROUTE: '/settings/admin/invoice-check-ignored-articles',
  },
  SETTINGS_MASTERDATA: {
    MAIN_ROUTE: '/settings',
    PERMISSIONS: [
      'user.',
      'site.',
      'accounting_reference.',
      'vehicle.',
      'company.',
      'org_unit.',
      'user_group.',
    ],
    RELATIVE: '/masterdata',
    ROUTE: '/settings/masterdata',
  },
  SETTINGS_ORGANISATIONAL_GROUP: {
    MAIN_ROUTE: '/settings',
    PERMISSIONS: ['org_unit.'],
    RELATIVE: '/masterdata/organisational-group',
    ROUTE: '/settings/masterdata/organisational-group',
  },
  SETTINGS_PDF: {
    MAIN_ROUTE: '/settings',
    ONLY_ACCESS_BY_VESTIGAS_SUPPORT: true,
    PERMISSIONS: ['org_unit.'],
    RELATIVE: '/admin/pdf',
    ROUTE: '/settings/admin/pdf',
  },
  SETTINGS_PROFILE: {
    MAIN_ROUTE: '/settings',
    RELATIVE: '/profile',
    ROUTE: '/settings/profile',
  },
  SETTINGS_SIGNATURE_FIELDS: {
    MAIN_ROUTE: '/settings',
    ONLY_ACCESS_BY_VESTIGAS_SUPPORT: true,
    PERMISSIONS: ['org_unit.'],
    RELATIVE: '/admin/signature-fields',
    ROUTE: '/settings/admin/signature-fields',
  },
  SETTINGS_SITE: {
    MAIN_ROUTE: '/settings',
    PERMISSIONS: ['site.'],
    RELATIVE: '/masterdata/site',
    ROUTE: '/settings/masterdata/site',
  },
  SETTINGS_SITE_REFERENCES: {
    MAIN_ROUTE: '/settings',
    ONLY_ACCESS_BY_VESTIGAS_SUPPORT: true,
    PERMISSIONS: ['org_unit.'],
    RELATIVE: '/admin/site-references',
    ROUTE: '/settings/admin/site-references',
  },
  SETTINGS_SUPPORT: {
    MAIN_ROUTE: '/settings',
    RELATIVE: '/support',
    ROUTE: '/settings/support',
  },
  SETTINGS_USER: {
    MAIN_ROUTE: '/settings',
    PERMISSIONS: ['user.'],
    RELATIVE: '/masterdata/employees',
    ROUTE: '/settings/masterdata/employees',
  },
  SETTINGS_USER_GROUP: {
    MAIN_ROUTE: '/settings',
    PERMISSIONS: ['user_group.'],
    RELATIVE: '/masterdata/user-group',
    ROUTE: '/settings/masterdata/user-group',
  },
  SETTINGS_VEHICLE: {
    MAIN_ROUTE: '/settings',
    PERMISSIONS: ['vehicle.'],
    RELATIVE: '/masterdata/vehicle',
    ROUTE: '/settings/masterdata/vehicle',
  },
};
