import { ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material';
import { Chip } from '@mui/material';
import clsx from 'clsx';
import {
  type CSSProperties,
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import ReactSelect, { components } from 'react-select';

// Re-export the createFilter function from react-select.
export { createFilter } from 'react-select';

export type Option<T = any> = {
  value: string;
  label: string;
  data: T;
};

export type GroupedOption<T> = {
  label: string;
  options: Array<Option<T>>;
};

export type SelectProps<T> = {
  readonly filterOption?: (option: Option<T>) => boolean;
  readonly formatOptionLabel?: (
    option: Option<T>,
    formatOptionLabelMeta: any,
  ) => ReactNode;
  readonly getItemData: (value: string) => Promise<T | undefined>;
  readonly getOptionLabel: (item: T) => string;
  readonly isClearable?: boolean;
  readonly isDisabled?: boolean;
  readonly isItemSelectionAllowed: () => boolean;
  readonly isLoading?: boolean;
  readonly isMultiSelect?: boolean;
  readonly onChange: {
    (value: string[], data: T[]): void;
    (value: string, data: T): void;
  };
  readonly options: Array<Option<T> | GroupedOption<T>>;
  readonly placeholder?: string;
  readonly testId?: string;
  readonly value: string | string[];
} & ComponentStyling;

const hideComponent = () => null;

// Always return the value as an array.
const ensureArray = (value: string | string[]) =>
  Array.isArray(value) ? value : value ? [value] : [];

const isGroupedOption = <T,>(
  option: Option<T> | GroupedOption<T>,
): option is GroupedOption<T> => 'options' in option;

const flattenOptions = <T,>(options: Array<Option<T> | GroupedOption<T>>) =>
  options.flatMap((option) =>
    isGroupedOption(option) ? option.options : option,
  );

export const Select = <T extends unknown>({
  className,
  filterOption,
  formatOptionLabel,
  isClearable = true,
  isDisabled = false,
  isItemSelectionAllowed = () => true,
  isLoading = false,
  isMultiSelect = false,
  onChange,
  options,
  placeholder = 'Element auswählen',
  style,
  testId,
  value,
}: SelectProps<T>) => {
  const [selectedItems, setSelectedItems] = useState<Array<Option<T>>>([]);

  useEffect(() => {
    if (!options) {
      setSelectedItems([]);
      return;
    }

    // Flatten option groups if present
    const flatOptions = flattenOptions(options);

    if (isMultiSelect) {
      const values = ensureArray(value);
      const selectedOptions = values
        .map((value_) => flatOptions.find((option) => option.value === value_))
        .filter(Boolean);

      setSelectedItems(selectedOptions);
    } else {
      const selectedOption = flatOptions.find(
        (option) => option.value === value,
      );

      setSelectedItems(selectedOption ? [selectedOption] : []);
    }
  }, [value, options, isMultiSelect]);

  const [error, setError] = useState<string | undefined>(undefined);

  const Control = ({ children, innerProps, ...rest }) => (
    <components.Control
      {...rest}
      innerProps={{
        ...innerProps,
        'data-testid': testId,
      }}
    >
      {children}
    </components.Control>
  );

  const MultiValue = ({ data }: { readonly data: Option<T> }) => (
    <Chip
      className="mr-1 first:-ml-1.5"
      label={data.label}
      onDelete={() => {
        handleChange(selectedItems.filter(({ value }) => value !== data.value));
      }}
    />
  );

  const DropdownIndicator = ({ selectProps }) => (
    <div
      className={clsx(
        'flex size-8 cursor-pointer items-center justify-center transition-transform',
        {
          'rotate-0': !selectProps.menuIsOpen,
          'rotate-180': selectProps.menuIsOpen,
        },
      )}
    >
      <ArrowDropDownIcon />
    </div>
  );

  const handleChange = useCallback(
    (newValue: Option<T> | Array<Option<T>> | undefined) => {
      const newSelectedItems = ensureArray(newValue);

      setSelectedItems(newSelectedItems);

      const newValues = newSelectedItems.map(({ value }) => value);
      const newData = newSelectedItems.map(({ data }) => data);

      onChange(
        isMultiSelect ? newValues : (newValues[0] ?? ''),
        isMultiSelect ? newData : newData[0],
      );
    },
    [onChange, isMultiSelect],
  );

  const memoizedStyles = useMemo(
    () => ({
      control: (provided: CSSProperties) => ({
        ...provided,
        minHeight: '2.5rem',
      }),
      menu: (provided: CSSProperties) => ({
        ...provided,
        margin: 0,
      }),
      menuPortal: (provided: CSSProperties) => ({
        ...provided,
        zIndex: 1500,
      }),
      valueContainer: (provided: CSSProperties) => ({
        ...provided,
        rowGap: '0.25rem',
      }),
      ...style,
    }),
    [style],
  );

  const selectedValue = isMultiSelect
    ? selectedItems
    : (selectedItems[0] ?? null);

  return (
    <>
      <ReactSelect
        className={className}
        components={{
          Control,
          DropdownIndicator,
          IndicatorSeparator: hideComponent,
          MultiValue,
        }}
        {...(filterOption === undefined ? {} : { filterOption })}
        formatOptionLabel={formatOptionLabel}
        isClearable={isClearable}
        isDisabled={isDisabled || !isItemSelectionAllowed()}
        isLoading={isLoading}
        isMulti={isMultiSelect}
        menuPortalTarget={document.body}
        noOptionsMessage={() => 'Keine passenden Daten gefunden'}
        options={options}
        placeholder={placeholder}
        styles={memoizedStyles}
        value={selectedValue}
        onChange={handleChange}
      />
      {error && <div className="mt-1 text-red-500">{error}</div>}
    </>
  );
};
