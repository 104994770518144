import { type CustomFieldOption } from './types';

/**
 * Represents a single custom field option with its associated properties.
 */
export const CustomFieldOptionObject = {
  /**
   * Creates a custom field option with the provided data.
   * @param customFieldOption - An object containing the custom field option data.
   */
  create(customFieldOption: Partial<CustomFieldOption> = {}) {
    return {
      id: customFieldOption.id ?? null,
      displayName: customFieldOption.displayName ?? null,
      description: customFieldOption?.description ?? null,
    };
  },
};
