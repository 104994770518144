import { createSlice } from '@reduxjs/toolkit';

import { LOADING_STATE } from '~/constants/LoadingState';

import { mapDeliveryRow } from '~/components/deliveries/utils';

const deliveryNotesSlice = createSlice({
  initialState: {
    deliveryNotes: [], // The unprocessed DLN data fetched from the API
    deliveryNotesLoading: LOADING_STATE.NOT_LOADED,
    deliveryNotesVersion: 0,
    deliveryNoteTemplates: [],
    deliveryNoteTemplatesLoading: LOADING_STATE.NOT_LOADED,
    deliveryRowCount: 0,
    deliveryRowCountLoading: LOADING_STATE.NOT_LOADED,
    deliveryRowPages: [],
    deliveryRowPagesAppliedFilters: {},
    deliveryRowPagesLoading: LOADING_STATE.NOT_LOADED,
    deliveryRowPagesVersion: 0,
    deliveryRows: [], // The mapped DLN data from the raw API response
    filteredDeliveryNotes: [],
    filteredDeliveryNotesVersion: 0,
    filteredDeliveryNotesVersionUpdateByBulkLoad: false,
    filteredDeliveryRows: [],
  },
  name: 'deliveryNotes',
  reducers: {
    updateDeliveryNotes(state, { payload: deliveryNotes }) {
      for (const updatedDln of deliveryNotes) {
        const updatedDeliveryRow = mapDeliveryRow(updatedDln);

        const oldDlnIndex = state.deliveryNotes.findIndex(
          ({ id }) => id === updatedDln.id,
        );
        const oldDlnExists = oldDlnIndex !== -1;

        if (oldDlnExists) {
          if (updatedDln.deleted) {
            // Remove old DLN, because it was deleted.
            state.deliveryNotes.splice(oldDlnIndex, 1);
            state.deliveryRows.splice(oldDlnIndex, 1);
          } else {
            // Update data with new DLN.
            state.deliveryNotes[oldDlnIndex] = updatedDln;
            state.deliveryRows[oldDlnIndex] = updatedDeliveryRow;
          }
        } else if (!updatedDln.deleted) {
          // Add new DLN.
          state.deliveryNotes.unshift(updatedDln);
          state.deliveryRows.unshift(updatedDeliveryRow);
        }
      }

      state.deliveryNotesVersion++;
    },
  },
});

const { actions, reducer } = deliveryNotesSlice;
export const {
  replaceDeliveryNoteTemplates,
  setDeliveryNoteTemplatesLoading,
  updateDeliveryNotes,
} = actions;
export default reducer;
