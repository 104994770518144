import { clone } from '~/utils/object';

export const sizeColumns = (columns, columnWidthModel) => {
  const newColumns = clone(columns);

  for (const [index, column] of newColumns.entries()) {
    const customWidth = columnWidthModel[column.field];
    if (customWidth) {
      newColumns[index].width = customWidth;
    }
  }

  return newColumns;
};
