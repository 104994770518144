import { type UUID } from '~/types/common';

import { ATTACHMENT_CONTENT_TYPE, ATTACHMENT_TYPE } from './constants';
import { type AttachmentBlobType, type AttachmentType } from './types';

export const AttachmentObject = {
  TYPE: ATTACHMENT_TYPE,

  CONTENT_TYPE: ATTACHMENT_CONTENT_TYPE,

  /**
   * Creates an attachment object with the given parameters.
   * @param {object} blob - The blob object containing attachment details.
   * @param {string} [chainId] - The chain ID associated with the attachment.
   * @param {string} [type] - The type of the attachment.
   * @returns {object} - The created attachment object.
   */
  create(blob: AttachmentBlobType, chainId: UUID | undefined, type: string) {
    return {
      digest: blob?.digest ?? '',
      contentType: blob?.contentType ?? '',
      chainId: chainId ?? '',
      type: type ?? '',
    };
  },

  /**
   * Checks if the given attachment is a delivery picture.
   * @param {object} attachment - The attachment object to check.
   * @returns {boolean} - true if the attachment is of type DELIVERY_PICTURE, otherwise false.
   */
  isDeliveryPicture(type: AttachmentType) {
    return type === this.TYPE.DELIVERY_PICTURE;
  },
};
