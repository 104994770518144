/**
 * This file sets up internationalization (i18n) for the application with i18next.
 * @see {@link https://www.i18next.com/overview/configuration-options}
 */
/*
 * Translations are automatically loaded with i18next-loader and bundled.
 * Fetching translations over the network instead of compiling them into the bundle
 * allows internationalization workflows where an internationalization service
 * (https://locize.com/, https://crowdin.com/, https://lokalise.com/)
 * places the translations in a CDN.
 * But this requires using suspense in the application to prevent race conditions,
 * where translations are not yet loaded, e.g. with react-router loader functions
 * that use i18n, which is a lot of additional effort.
 */
import i18n from 'i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import resources from 'virtual:i18next-loader';

// Include all languages supported by the app here:
const supportedLanguages = ['de', 'en'] as const;

export type Locale = (typeof supportedLanguages)[number];

export const currentLocale = (): Locale => i18n.resolvedLanguage as Locale;

// Include all namespaces used by the app here. Each module should have its own namespace.
export const translationNamespaces = [
  'common',
  'home',
  'forms',
  'masterData',
  'pwa',
  'units',
] as const;
export const defaultNamespace = 'common';

export type i18nNamespace = (typeof translationNamespaces)[number];

// .use(LanguageDetector)
i18n.use(initReactI18next).init({
  resources,
  compatibilityJSON: 'v4', // Enable current pluralization, see https://www.i18next.com/translation-function/plurals
  debug: false,
  defaultNS: defaultNamespace,
  fallbackLng: ['de', 'en'],
  interpolation: {
    escapeValue: false, // XSS protection - not needed as React escapes by default.
  },
  lng: 'de', // Set the language. Don't set when using a language detector!
  // keySeparator: false, // Set to false if working with a flat JSON file.
  ns: translationNamespaces,
  react: {
    useSuspense: false,
  },
  supportedLngs: supportedLanguages,
});

// Set locale for update notice
globalThis.pluginWebUpdateNotice_?.setLocale?.(currentLocale());

export { default } from 'i18next';
