import store from '~/redux/store';

class BrowserUtils {
  isWideScreen() {
    return window.innerWidth > 1200;
  }

  // Screen size is smaller than sm of MUI Grid. Hence, alignment of Grid items according to xs is applied.
  isGridXsScreen() {
    return window.innerWidth < 1200;
  }

  openNewTab(to) {
    const newWindow = window.open(to, '_blank');
    newWindow.redux_state = store.getState(); // pass the whole state to the new tab
  }

  openMenuItemInNewTab(menuItemName, to) {
    const newWindow = window.open(to, '_blank');
    newWindow.redux_state = store.getState(); // pass the whole state to the new tab
    newWindow.menu_item_name = menuItemName; // pass the clicked menu item so that the state can be changed via the menu item callback in the new tab
  }
}

const browserUtilsInstance = new BrowserUtils();

export default browserUtilsInstance;
