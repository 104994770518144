import {
  FormHelperText,
  InputLabel,
  TextField as MuiTextField,
} from '@mui/material';

export default function TextField(props) {
  return (
    <div className={'relative ' + props.className}>
      <InputLabel
        className={'text-sm ' + (props.error ? 'text-mui-error-red' : '')}
      >
        {props.title}
      </InputLabel>
      <MuiTextField
        {...props}
        autoComplete="off"
        error={Boolean(props.error)}
        placeholder={props.placeholder}
        size="small"
        value={props.value}
        variant="outlined"
        fullWidth
        onChange={props.onChange}
      />
      {props.error ? (
        <div className="flex w-full justify-end">
          <FormHelperText className="text-mui-error-red absolute">
            {props.error}
          </FormHelperText>
        </div>
      ) : null}
    </div>
  );
}
