import { type UUID } from '~/types/common';

import { isCustomFieldName } from '~/utils/customField';
import { toCamelCase } from '~/utils/string';

type CustomFieldData = {
  displayName: string;
  id: UUID;
  key: string;
  level: 'global' | 'item';
  name: string;
};

type ReportFieldConfig = {
  customFieldId?: string;
  name: string;
};

const getCustomFieldData = (customField, customFields = []) => {
  const customFieldData = customFields.find(({ key }) => key === customField);

  return customFieldData;
};

const getCustomFieldConfig = (customField, customFields = []) => {
  const customFieldData = getCustomFieldData(customField, customFields);

  if (!customFieldData) {
    return null;
  }

  return {
    customFieldId: customFieldData.id,
    name:
      customFieldData.level === 'global'
        ? 'custom_field_global_level'
        : 'custom_field_item_level',
  };
};

const getFieldConfig = (field) => {
  return {
    name: field,
  };
};

export const getReportConfiguration = (
  fields,
  customFields: CustomFieldData[],
): ReportFieldConfig[] => {
  const fieldsConfiguration = fields
    .map((field) => {
      if (!isCustomFieldName(field)) {
        return getFieldConfig(field);
      }

      return getCustomFieldConfig(field, customFields);
    })
    .filter(Boolean);

  return fieldsConfiguration;
};

export const getReportOrder = (
  fields: string[],
  customFields: CustomFieldData[],
) => {
  const reportOrder = fields
    .map((field) => {
      if (!isCustomFieldName(field)) {
        return field;
      }

      const customFieldData = getCustomFieldData(field, customFields);

      return customFieldData?.id;
    })
    .filter(Boolean);

  // Make sure amount and unit type are always included and at the end.
  const reportOrderWithAmountAndUnit = [
    ...reportOrder.filter(
      (field) => !['amount', 'unitType'].includes(toCamelCase(field)),
    ),
    'amount',
    'unitType',
  ];

  return reportOrderWithAmountAndUnit;
};
