import { EMPTY_DROPDOWN_OPTION } from '~/constants/select';

import { AcceptStateCalculatorObject } from '~/models/acceptState';
import { DeliveryNoteObject } from '~/models/deliveries/DeliveryNote';
import {
  FILTER_FIELD_NAME_BACKEND,
  FILTER_OPERATOR_BACKEND,
} from '~/models/filters/constants';
import FilterNew from '~/models/filters/FilterNew';

import { dayjs } from '~/utils/datetime';
import { dateUtils } from '~/utils/dateUtils';

import {
  type FilterClause,
  type FilterClauseName,
  type FilterComparator,
  type FilterFieldName,
  type FilterGroup,
  type FilterOperator,
} from './types';

/**
 * Creates filter clauses for a date range query.
 * Converts the date range into backend filter format with proper ISO formatting.
 *
 * @param dateRange - Tuple containing start and end dates
 * @returns Array of filter clauses for the date range
 */
const createDateRangeFilters = (dateRange: [Date, Date]) => {
  if (!dateRange) {
    return [];
  }

  const [startDate, endDate] = dateRange;
  const formattedStartDate = dateUtils.getFormattedDate(
    dayjs(startDate), // start date is 'greater than or equal to (>=) start date'
    dateUtils.DATE_FORMAT.YYYY_MM_DD_ISO,
  );
  const formattedEndDate = dateUtils.getFormattedDate(
    dayjs(endDate).add(1, 'day'), // end date is 'less than (<) next day'
    dateUtils.DATE_FORMAT.YYYY_MM_DD_ISO,
  );

  return [
    {
      comp: FILTER_OPERATOR_BACKEND.GREATER_THAN_OR_EQUALS,
      name: 'dln_date',
      value: formattedStartDate,
    },
    {
      comp: FILTER_OPERATOR_BACKEND.LESS_THAN,
      name: 'dln_date',
      value: formattedEndDate,
    },
  ] satisfies FilterClause[];
};

const createProcessStateFilter = (processStates: string[]) => {
  if (!processStates?.length) {
    return null;
  }

  const processStateValues = processStates.flatMap((processStateString) => {
    const processState =
      DeliveryNoteObject.getProcessStateKeyByString(processStateString);
    return processState === DeliveryNoteObject.PROCESS_STATE.DELIVERED.API_KEY
      ? [processState, DeliveryNoteObject.PROCESS_STATE.SETTLED.API_KEY]
      : [processState];
  });

  return {
    comp: FILTER_OPERATOR_BACKEND.IS_ANY_OF,
    name: FILTER_FIELD_NAME_BACKEND.PROCESS_STATE,
    value: processStateValues,
  } satisfies FilterClause;
};

const createAcceptStatesFilter = (acceptStates: string[]) => {
  if (!acceptStates?.length) {
    return null;
  }

  const acceptStateValues = acceptStates.map((state) =>
    AcceptStateCalculatorObject.getBackendAcceptStateKey(state),
  );

  return {
    comp: FILTER_OPERATOR_BACKEND.IS_ANY_OF,
    name: FILTER_FIELD_NAME_BACKEND.DLN_ACCEPT_STATE,
    value: acceptStateValues,
  } satisfies FilterClause;
};

const createSettledStatusFilter = (settledStatus: string[]) => {
  if (!settledStatus || settledStatus.length === 0) {
    return null;
  }

  const settledStatusValues = settledStatus.map((state) =>
    state?.toLowerCase() === 'ja' ? 'fully_billed' : 'unbilled',
  );

  return {
    comp: FILTER_OPERATOR_BACKEND.IS_ANY_OF,
    name: FILTER_FIELD_NAME_BACKEND.DLN_SETTLED_STATUS,
    value: settledStatusValues,
  } satisfies FilterClause;
};

const createCategoryFilter = (category: string[]) => {
  if (!category || category.length === 0) {
    return null;
  }

  const categoryValues = category
    .map((state) => {
      switch (state.toLowerCase()) {
        case 'eingehend': {
          return 'incoming';
        }

        case 'ausgehend': {
          return 'outgoing';
        }

        case 'intern': {
          return 'internal';
        }

        default: {
          return undefined;
        }
      }
    })
    .filter(Boolean);

  return {
    comp: FILTER_OPERATOR_BACKEND.IS_ANY_OF,
    name: FILTER_FIELD_NAME_BACKEND.CATEGORY,
    value: categoryValues,
  } satisfies FilterClause;
};

/**
 * Creates a filter group for the global sites and cost centers filter.
 * Includes both the confirmed and authorized sites and cost centers to make sure everything relevant is included.
 *
 * @param {string[]} permittedSites - The permitted sites.
 * @param {string[]} permittedCostCenters - The permitted cost centers.
 * @returns {Object[]} The filter group.
 */
const createAuthorizedSitesAndCostCentersFilterGroup = (
  authorizedSites: string[],
  authorizedCostCenters: string[],
): FilterGroup[] => {
  const filterClauses = [];

  if (authorizedSites.length > 0) {
    filterClauses.push({
      comp: FILTER_OPERATOR_BACKEND.IS_ANY_OF,
      name: FILTER_FIELD_NAME_BACKEND.AUTHORIZED_SITE_ID,
      value: authorizedSites,
    });
  }

  if (authorizedCostCenters.length > 0) {
    filterClauses.push({
      comp: FILTER_OPERATOR_BACKEND.IS_ANY_OF,
      name: FILTER_FIELD_NAME_BACKEND.AUTHORIZED_ACCOUNTING_REFERENCE_ID,
      value: authorizedCostCenters,
    });
  }

  return [
    {
      filterAggr: FilterNew.BOOLEAN_OPERATOR.OR,
      filterClauses,
    },
  ];
};

const createCustomFieldFilters = (
  selectedCustomFields,
  definedCustomFields,
) => {
  if (
    !Array.isArray(selectedCustomFields) ||
    selectedCustomFields.length === 0 ||
    !Array.isArray(definedCustomFields) ||
    definedCustomFields.length === 0
  ) {
    return [];
  }

  const customFieldFilters = selectedCustomFields.map((customField) => {
    const definedCustomField = definedCustomFields.find(
      ({ key }) => key === customField.key,
    );

    if (!definedCustomField) {
      return null;
    }

    const hasNoValues =
      customField.filterValue.map((value) => value?.trim()).filter(Boolean)
        .length === 0;
    if (hasNoValues) {
      return null;
    }

    let processedFilterValue = customField.filterValue;
    if (definedCustomField.type === 'bool') {
      // TODO: abstract current recognition of true values by German string "Ja"
      processedFilterValue = ['Ja'].includes(customField.filterValue?.[0]);
    }

    return {
      comp: Array.isArray(processedFilterValue)
        ? FILTER_OPERATOR_BACKEND.IS_ANY_OF
        : FILTER_OPERATOR_BACKEND.EQUALS,
      customFieldId: definedCustomField.id,
      name:
        definedCustomField.level === 'global'
          ? 'custom_field_global_level'
          : 'custom_field_item_level',
      value: processedFilterValue,
    };
  });

  return customFieldFilters.filter(Boolean);
};

const createSimpleArrayFilter = (
  values: string[],
  name: FilterFieldName,
  operator: FilterOperator = FILTER_OPERATOR_BACKEND.IS_ANY_OF,
) => {
  if (!values?.length) {
    return null;
  }

  const processedValues = values.map((value) =>
    value === EMPTY_DROPDOWN_OPTION ? '' : value,
  );

  return {
    comp: operator,
    name,
    value: processedValues,
  } satisfies FilterClause;
};

const createQueryFilter = (query: string) => {
  if (!query) {
    return null;
  }

  return {
    comp: FILTER_OPERATOR_BACKEND.CONTAINS,
    name: FILTER_FIELD_NAME_BACKEND.QUERY,
    value: query,
    caseSensitive: false,
  } satisfies FilterClause;
};

/**
 * Returns the search body for the delivery notes.
 *
 * Takes filter groups and an optional filter aggregator as parameters and returns a DlnFilterConfig.
 * @see  https://app.dev.vestigas.com/redoc#tag/Analytics/operation/compute_dln_analytics_suggestions_analytics_suggestions_post for an example what the DlnFilterConfig looks like as used in an API endpoint.
 *
 * @param {object} options.filterGroups - The filter groups object.
 * @param {string} options.filterAggregator - The filter aggregator - defaults to AND
 * @return {object} The search body.
 */
export const getSearchBody = ({
  filterGroups: {
    acceptStates = [],
    article = [],
    articleNumber = [],
    category = [],
    dateRange,
    fromSite = [],
    permittedCostCenters = [],
    permittedToSites = [],
    processStates = [],
    query = '',
    recipients = [],
    selectedCostCenter = [],
    selectedCostCenters = [],
    selectedCustomFields = [],
    selectedOuId = [],
    selectedSites = [],
    settledStatus = [],
    suppliers = [],
    toSiteRecipient = [],
    toSiteSupplier = [],
  },
  filterAggregator = FilterNew.BOOLEAN_OPERATOR.AND,
  definedCustomFields,
}) => {
  const mappedFilterGroups: FilterGroup[] = [];

  // Handle permitted sites and cost centers.
  if (
    (selectedSites && selectedSites.length > 0) ||
    (selectedCostCenters && selectedCostCenters.length > 0)
  ) {
    mappedFilterGroups.push(
      ...createAuthorizedSitesAndCostCentersFilterGroup(
        selectedSites,
        selectedCostCenters,
      ),
    );
  }

  // Create generic filters group.
  const genericFilters: FilterClause[] = [
    ...createDateRangeFilters(dateRange),
    createAcceptStatesFilter(acceptStates),
    createCategoryFilter(category),
    createProcessStateFilter(processStates),
    createSettledStatusFilter(settledStatus),

    createSimpleArrayFilter(
      toSiteRecipient,
      FILTER_FIELD_NAME_BACKEND.CONFIRMED_SITE_NAME,
    ),
    createSimpleArrayFilter(
      toSiteSupplier,
      FILTER_FIELD_NAME_BACKEND.SUPPLIER_ASSIGNED_SITE_NAME,
    ),
    createSimpleArrayFilter(article, FILTER_FIELD_NAME_BACKEND.ARTICLE_NAME),
    createSimpleArrayFilter(
      articleNumber,
      FILTER_FIELD_NAME_BACKEND.ARTICLE_NUMBER,
    ),
    createSimpleArrayFilter(suppliers, FILTER_FIELD_NAME_BACKEND.SUPPLIER_NAME),
    createSimpleArrayFilter(
      recipients,
      FILTER_FIELD_NAME_BACKEND.RECIPIENT_NAME,
    ),
    createSimpleArrayFilter(
      permittedToSites,
      FILTER_FIELD_NAME_BACKEND.AUTHORIZED_SITE_ID,
    ),
    createSimpleArrayFilter(
      permittedCostCenters,
      FILTER_FIELD_NAME_BACKEND.AUTHORIZED_ACCOUNTING_REFERENCE_ID,
    ),
    createSimpleArrayFilter(
      selectedCostCenter,
      FILTER_FIELD_NAME_BACKEND.COST_CENTER,
    ),
    createSimpleArrayFilter(
      fromSite,
      FILTER_FIELD_NAME_BACKEND.LOADING_LOCATION,
    ),
    createSimpleArrayFilter(selectedOuId, FILTER_FIELD_NAME_BACKEND.OU_ID),
    createQueryFilter(query),
    ...createCustomFieldFilters(selectedCustomFields, definedCustomFields),
  ].filter(Boolean);

  if (genericFilters.length > 0) {
    mappedFilterGroups.push({
      filterAggr: FilterNew.BOOLEAN_OPERATOR.AND,
      filterClauses: genericFilters,
    });
  }

  return {
    filterConfig: {
      filterAggr: filterAggregator,
      filterGroups: mappedFilterGroups,
    },
  };
};

export const getUnassignedDeliveryNotesSearchBody = (
  dateRange: [Date, Date],
  query?: string,
) => {
  return {
    filterConfig: {
      filterAggr: 'and',
      filterGroups: [
        {
          filterAggr: 'and',
          filterClauses: [
            ...createDateRangeFilters(dateRange),
            createQueryFilter(query),
            {
              comp: 'eq',
              name: 'is_mapped',
              value: false,
            },
          ].filter(Boolean),
        },
      ],
    },
  };
};
