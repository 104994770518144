export const ComponentTestIds = {
  FORM_MODAL: {
    CLOSE_BUTTON: 'Close-form-modal',
    OPEN_BUTTON: 'Open-form-modal',
    SUBMIT_BUTTON: 'Submit-form-modal',
    TITLE: 'Form-modal',
  },
  MULTI_SELECT: {
    LIST_SERVER_DRIVEN_ITEM_ASSIGNABLE: 'List-server-driven-item',
    OPEN_EDIT_MULTI_SELECT_BUTTON: 'Edit-multi-select-button',
    SAVE_MULTI_SELECT_BUTTON: 'Save-multi-select-button',
  },
  SEARCH_INPUT: {
    INPUT: 'Search-input',
  },
  TABLE: {
    DATAGRID: 'Datagrid',
    EMPTY_TABLE: 'BasicTable-empty-row',
    LOADING: 'Table-loading',
    PAGINATION: 'Datagrid-pagination',
  },
  TOPBAR: {
    PAGETITLE: 'Topbar-pagetitle',
  },
} as const;
