import {
  useQueries,
  useQuery,
  type UseQueryResult,
} from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { type Address } from '~/types/address';
import { type UUID } from '~/types/common';
import { type Permission } from '~/types/permission';

import { vestigasApi } from '~/services/kyClient';

import { queryKeysSite } from './queryKeys';

type Identifier = {
  ignoreAddressInfo: boolean;
  blacklisted: boolean;
  address: Address;
  identifierUuid: UUID;
  identifier: string;
  identifyingId?: string;
  supplierId: UUID;
  supplierName: string;
};

export type Site = {
  accountingReferenceList: string[];
  accRefSyncKeys: any | undefined;
  address: Address;
  companyAccountId: UUID;
  companyId: UUID;
  companySyncKey: any | undefined;
  coords: any | undefined;
  end: string | undefined;
  id: UUID;
  identifierList: Identifier[];
  isActive: boolean;
  name: string;
  orgUnitPaths: UUID[][];
  orgUnits: UUID[];
  orgUnitsSyncKeys: any | undefined;
  permissionsFrom: Array<{
    id: number;
    subjectId: UUID;
    subjectType:
      | 'accounting_reference'
      | 'company'
      | 'org_unit'
      | 'site'
      | 'user_group'
      | 'user'
      | 'vehicle';
    permissions: Permission[];
  }>;
  start: string | undefined;
  syncKey: any | undefined;
  type: 'construction_site' | 'disposal' | 'factory' | 'plant' | 'warehouse';
};

/**
 * Fetches site data from the API.
 * @param {string} siteId - The ID of the site to fetch.
 * @returns {Promise<Site|null>} The site data if successful, null otherwise.
 * @see https://app.dev.vestigas.com/redoc#tag/Site/operation/get_site_site__site_uuid__get
 */
export const fetchSite = async (siteId: UUID) => {
  try {
    const response = await vestigasApi
      .get(ENDPOINT.SITE.GET(siteId))
      .json<Site>();

    return response;
  } catch (error) {
    console.error('Error fetching site', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * React Query based custom hook for getting the data for a site with a given siteId.
 * @param {string} siteId - The ID of the site to fetch.
 * @param {Object} options - Additional options for the useQuery hook.
 * @returns {UseQueryResult<Site | null>} The result of the useQuery hook.
 */
export const useQuerySite = (
  siteId: UUID,
  options?: Record<string, unknown>,
): UseQueryResult<Site | undefined> =>
  useQuery({
    enabled: Boolean(siteId),
    queryFn: async () => fetchSite(siteId),
    queryKey: queryKeysSite.get(siteId),
    ...options,
  });

/**
 * React Query based custom hook for getting data for multiple sites based on a list of site IDs.
 * @param {UUID[]} siteIds - Array of site IDs to fetch.
 * @param {Object} options - Additional options for the useQueries hook.
 * @param {Function} combine - Optional function to process the results of the different queries.
 * @returns {UseQueryResult<Site | undefined>[]} Array of query results for each site. If a combine
 *                                              function is provided, the results will be transformed
 *                                              according to that function.
 */
export const useQueriesSites = (
  siteIds: UUID[],
  options?: Record<string, unknown>,
  combine?: (results: Array<UseQueryResult<Site | undefined>>) => unknown,
) =>
  useQueries({
    combine,
    queries: siteIds.map((siteId) => ({
      enabled: Boolean(siteId),
      queryFn: async () => fetchSite(siteId),
      queryKey: queryKeysSite.get(siteId),
      ...options,
    })),
  });
