import ObjectUtils from '~/utils/objectUtils';

import { AttachmentObject } from './attachmentUtils';
import { type DeliveryNoteCreatedValues } from './DeliveryNote/types';
import {
  type AttachmentCreatedObject,
  type DeliveryNoteActionCreatedObject,
} from './types';

export const DeliveryNoteAttachmentHandlerObject = {
  attachments: [] as AttachmentCreatedObject[],

  /**
   * Creates a new DeliveryNoteAttachmentHandler instance with default values.
   * @returns {object} - The DeliveryNoteAttachmentHandler instance.
   */
  create() {
    return { ...this };
  },

  /**
   * Add the attachments from different places of the asset chain to the attachment handler.
   * @param {Object} assetChain - The asset chain containing attachments.
   */
  addAttachmentsFromChain(assetChain: Record<string, any>) {
    this.addGeneralAttachmentFromChain(assetChain);
    this.addGeneralAttachmentsFromChain(assetChain);
    this.addAcceptArticleAttachmentsFromChain_legacy(assetChain);
  },

  /**
   * Adds a general attachment from the asset chain if available.
   * @param {Object} assetChain - The asset chain containing the attachment.
   */
  addGeneralAttachmentFromChain(assetChain: Record<string, any>) {
    if (!assetChain.attachment) {
      return;
    }

    const attachment = AttachmentObject.create(
      assetChain.attachment.blob,
      assetChain.id,
      assetChain.attachment.comment,
    );

    this.attachments.push(attachment);
  },

  /**
   * Adds all general attachments from the asset chain to the handler.
   * @param {Object} assetChain - The asset chain containing the attachments.
   */
  addGeneralAttachmentsFromChain(assetChain: Record<string, any>) {
    if (!assetChain.attachments) {
      return;
    }

    for (const path of Object.keys(assetChain.attachments)) {
      const blob = assetChain.attachments[path];
      let pathArray = path.split('/');
      pathArray = pathArray.filter(
        (pathItem) => pathItem !== 'blob_' && pathItem !== 'blob',
      );
      const type = ObjectUtils.accessObjectRecursively(
        assetChain,
        pathArray,
      )?.type;

      const attachment = AttachmentObject.create(
        blob,
        assetChain.id,
        type ?? null,
      );

      if (
        this.attachments.some(
          (existingAttachment) =>
            existingAttachment.digest === attachment.digest,
        )
      ) {
        continue;
      }

      this.attachments.push(attachment);
    }
  },

  /**
   * Adds accept article attachments from legacy data.
   * @param {Object} assetChain - The asset chain containing the legacy data.
   */
  addAcceptArticleAttachmentsFromChain_legacy(assetChain: Record<string, any>) {
    if (!assetChain.meta?.acceptEvent?.acceptedItems) {
      return;
    }

    for (const acceptedItem of assetChain.meta.acceptEvent.acceptedItems) {
      if (!acceptedItem.attachments) {
        continue;
      }

      for (const chainAttachment of acceptedItem.attachments) {
        const attachment = AttachmentObject.create(
          chainAttachment.blob,
          assetChain.id,
          chainAttachment.type,
        );

        if (
          this.attachments.some(
            (existingAttachment) =>
              existingAttachment.digest === attachment.digest,
          )
        ) {
          continue;
        }

        this.attachments.push(attachment);
      }
    }
  },

  /**
   * Populates the accept article chain IDs in the delivery note.
   * @param {Object} deliveryNote - The delivery note object to update.
   */
  populateAcceptArticleChainIds(deliveryNote: DeliveryNoteCreatedValues) {
    for (const article of deliveryNote.articles) {
      this.populateChainIdForAttachments(
        article.acceptArticleSupplier.attachments,
        deliveryNote,
      );
      this.populateChainIdForAttachments(
        article.acceptArticleCarrier.attachments,
        deliveryNote,
      );
      this.populateChainIdForAttachments(
        article.acceptArticleRecipient.attachments,
        deliveryNote,
      );
      this.populateChainIdForAttachments(
        article.acceptArticleOnBehalfSupplier.attachments,
        deliveryNote,
      );
      this.populateChainIdForAttachments(
        article.acceptArticleOnBehalfCarrier.attachments,
        deliveryNote,
      );
      this.populateChainIdForAttachments(
        article.acceptArticleOnBehalfRecipient.attachments,
        deliveryNote,
      );
    }
  },

  /**
   * Helper method to populate chain IDs for attachments in articles.
   * @param {Array} attachments - The list of attachments to process.
   * @param {Object} deliveryNote - The delivery note to check for matching attachments.
   */
  populateChainIdForAttachments(
    attachments: AttachmentCreatedObject[],
    deliveryNote: DeliveryNoteCreatedValues,
  ) {
    for (const acceptArticleAttachment of attachments) {
      const attachment = deliveryNote.attachmentHandler.attachments.find(
        (attachment) => attachment.digest === acceptArticleAttachment.digest,
      );
      if (!attachment) {
        continue;
      }

      acceptArticleAttachment.chainId = attachment.chainId;
    }
  },

  /**
   * Populates delivery note actions with the corresponding attachments.
   * @param {Array} deliveryNoteActions - The list of delivery note actions to update.
   */
  populateDeliveryNoteActions(
    deliveryNoteActions: DeliveryNoteActionCreatedObject[],
  ) {
    for (const deliveryNoteAction of deliveryNoteActions) {
      deliveryNoteAction.attachments = this.attachments.filter(
        ({ chainId }) => chainId === deliveryNoteAction.id,
      );
    }
  },
};
