import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { type UUID } from '~/types/common';
import { type UserSignatureRoles } from '~/types/userSignatureRoles';

import { vestigasApi } from '~/services/kyClient';
import ToastService from '~/services/toast.service';

import {
  SIGNATURE_ROLE,
  SignatureRolesObject,
} from '~/models/masterdata/SignatureRoles';

import { unique } from '~/utils/array';
import { Log } from '~/utils/logging';

import { queryKeysUser } from './queryKeys';

const translatedSignatureRoles = Object.fromEntries(
  Object.entries(SIGNATURE_ROLE).map(([_, { KEY, STRING }]) => [KEY, STRING]),
);

/**
 * Updates the signature roles of a given user.
 *
 * @param {string} userId - The ID of the user to update.
 * @param {Object} featureFlags - An object containing the feature flags to update.
 * @returns {Promise} A promise that resolves with the API response.
 *
 * @see https://app.dev.vestigas.com/redoc#tag/User/operation/set_user_permitted_signature_roles_user__user_uuid__signature_roles_put
 */
export const updateUserSignatureRoles = async (
  userId: UUID,
  userSignatureRoles: UserSignatureRoles,
) => {
  try {
    const body = { ...userSignatureRoles };

    // defaultSignatureRole is not accepted by the backend in the signature roles object.
    delete body.defaultSignatureRole;

    await vestigasApi
      .put(ENDPOINT.USER.UPDATE_SIGNATURE_ROLES(userId), {
        json: body,
      })
      .json();

    return body; // Our API does not return the updated values so we are just assuming it returns what we sent.
  } catch (error) {
    Log.error('Error updating user signature roles', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * Custom hook for updating the signature roles of a given user.
 *
 * @returns {Object} An object containing the mutation function and related properties.
 */
export const useMutationUpdateUserSignatureRoles = (
  options?: Parameters<typeof useMutation>[0],
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({
      userId,
      userSignatureRoles,
    }: {
      userId: UUID;
      userSignatureRoles: UserSignatureRoles;
    }) => updateUserSignatureRoles(userId, userSignatureRoles),
    onError(error) {
      Log.productAnalyticsEvent(
        'Failed to update user signature roles',
        Log.FEATURE.USER,
        Log.TYPE.ERROR,
      );

      const lines = ['xXxXxXxXx'];
      ToastService.httpError(lines, error);
    },
    onSuccess(data, { userId }) {
      queryClient.invalidateQueries({
        queryKey: queryKeysUser.getSignatureRoles(userId),
      });

      let message =
        'Damit das Update der Signaturberechtigung wirksam wird, bitte in der mobilen App neu einloggen.';

      const hasActiveRoles = Object.values(data).includes(true);
      if (hasActiveRoles) {
        const descriptiveSignatureRoles = unique(
          SignatureRolesObject.getSignatureRoles(data)
            .map((role) => translatedSignatureRoles[role])
            .filter(Boolean),
        ).join(', ');

        message = `Damit die Signaturberechtigungen (${descriptiveSignatureRoles}) wirksam werden, bitte in der mobilen App neu einloggen.`;
      }

      ToastService.warning(message);

      Log.productAnalyticsEvent(
        'Update user signature roles success',
        Log.FEATURE.USER,
      );
    },
    ...options,
  });
};
