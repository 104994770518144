import urlcat from 'urlcat';

import { type UUID } from '~/types/common';
import { type EntityType } from '~/types/entity';

/**
 * Paths for all endpoints of the VESTIGAS API used in the application.
 */
export const ENDPOINT = {
  ARTICLE: {
    CREATE: () => 'article_master/article',
    DELETE: (articleId: UUID) =>
      urlcat('article_master/article/:articleId', { articleId }),
    GET_ALL: () => 'article_master/article',
    UPDATE: (articleId: UUID) =>
      urlcat('article_master/article/:articleId', { articleId }),
  },
  CATEGORY: {
    CREATE: () => 'article_master/category',
    DELETE: (categoryId: UUID) =>
      urlcat('article_master/category/:categoryId', { categoryId }),
    DELETE_ICON: (categoryIconId: UUID) =>
      urlcat('article_master/category/:categoryIconId/icon', {
        categoryIconId,
      }),
    GET_ALL: () => 'article_master/category',
    GET_ICON: (categoryIconId: UUID) =>
      urlcat('article_master/category/:categoryIconId/icon', {
        categoryIconId,
      }),
    UPDATE: (categoryId: UUID) =>
      urlcat('article_master/category/:categoryId', { categoryId }),
    UPDATE_ICON: (categoryIconId: UUID) =>
      urlcat('article_master/category/:categoryIconId/icon', {
        categoryIconId,
      }),
  },
  COMPANY: {
    CREATE: () => 'company',
    DELETE_LOGO: (companyId: UUID): string =>
      urlcat('company/:companyId/logo', { companyId }),
    GET: (companyId: UUID): string =>
      urlcat('company/:companyId', { companyId }),
    GET_ALL: () => 'company',
    GET_ALL_LEGACY: () => 'company/all',
    GET_ALLOWED_BULK: (companyId: UUID) =>
      urlcat('company/bulk_allowed_companies', { company_id: companyId }),
    GET_DLN_TO_INVOICE: (companyId: UUID): string =>
      urlcat('company/:companyId/dln_to_invoice', { companyId }),
    GET_INVOICE_PROTOCOL: (companyId: UUID): string =>
      urlcat('company/:companyId/invoice_protocol_to_invoice', { companyId }),
    // endpoint uses query parameters in snake case
    GET_LOGO: (companyId: UUID): string =>
      urlcat('company/:companyId/logo', { companyId }),
    UPDATE_COMPANY_DLN_TO_INVOICE: (companyId: UUID) =>
      urlcat('company/:companyId/dln_to_invoice', { companyId }),
    UPDATE_INVOICE_PROTOCOL_TO_INVOICE: (companyId: UUID) =>
      urlcat('company/:companyId/invoice_sending_settings', { companyId }),
    UPLOAD_LOGO: (companyId: UUID): string =>
      urlcat('company/:companyId/logo', { companyId }),
    UPSERT: (companyId: UUID): string =>
      urlcat('company/:companyId', { companyId }),
  },
  COST_CENTER: {
    CREATE: () => 'accounting_reference',
    GET: (costCenterId: UUID): string =>
      urlcat('accounting_reference/:costCenterId', { costCenterId }),
    GET_ALL_LEGACY: () => 'accounting_reference/all',
    GET_ALL: () => 'accounting_reference',
    GET_BULK: () => 'accounting_reference/query/bulk',
    UPDATE: (costCenterId: UUID): string =>
      urlcat('accounting_reference/:costCenterId', { costCenterId }),
  },
  CUSTOM_FIELD: {
    CREATE: () => 'custom_field',
    CREATE_OPTION: (customFieldId: UUID) =>
      urlcat('custom_field/:customFieldId/option', { customFieldId }),
    DELETE: (customFieldId: UUID) =>
      urlcat('custom_field/:customFieldId', { customFieldId }),
    DELETE_OPTION: (customFieldId: UUID, customFieldOptionId: UUID) =>
      urlcat('custom_field/:customFieldId/option/:customFieldOptionId', {
        customFieldId,
        customFieldOptionId,
      }),
    GET_ALL: () => 'custom_field/all',
    GET_ALL_LEGACY: () => 'custom_field/all',
    GET_BULK: () => 'custom_field/query/bulk',
    GET_OPTIONS: (customFieldId: UUID) =>
      urlcat('custom_field/:customFieldId/option', { customFieldId }),
    UPDATE: (customFieldId: UUID) =>
      urlcat('custom_field/:customFieldId', { customFieldId }),
    GET_OPTION: (customFieldId: UUID, customFieldOptionId: UUID) =>
      urlcat('custom_field/:customFieldId/option/:customFieldOptionId', {
        customFieldId,
        customFieldOptionId,
      }),
  },
  CUSTOMER_SUBSCRIPTION: {
    CREATE: () => 'customer_subscription',
    GET_ALL: () => 'customer_subscription',
    TEST: () => 'customer_subscription/test',
    UPDATE: (customerSubscriptionId: UUID) =>
      urlcat('customer_subscription/:customerSubscriptionId', {
        customerSubscriptionId,
      }),
  },
  DATA_EXCHANGE: {
    GET_ALL_QUERY: () => 'data_exchange/company_information/query',
    CREATE_EMAIL_WITH_REG_LINK: () => 'data_exchange/contact_link',
    GET_ALL_LEGACY: () => 'data_exchange/all',
    GET_COMPANIES: () => 'data_exchange/company_information/all',
    GET_ELIGIBLE_RESPONSIBLE_PERSONS: (dataExchangeId: UUID) =>
      urlcat('data_exchange/:dataExchangeId/eligible_responsible_persons', {
        dataExchangeId,
      }),
    GET_EXCEL_DATA: () => 'data_exchange/company_information/excel',
  },
  DATA_SUBSCRIPTION: {
    CREATE_CONTACT_POINT: () => 'data_subscription/contact_point',
    CREATE_DATA_SUBSCRIPTION: () => 'data_subscription',
    CREATE_NOTIFICATION_POLICY: () => 'data_subscription/notification_policy',
    DELETE_CONTACT_POINT: (contactPointId: UUID) =>
      urlcat('data_subscription/contact_point/:contactPointId', {
        contactPointId,
      }),
    DELETE_DATA_SUBSCRIPTION: (dataSubscriptionId: UUID) =>
      urlcat('data_subscription/:dataSubscriptionId', { dataSubscriptionId }),
    DELETE_NOTIFICATION_POLICY: () =>
      'data_subscription/notification_policy/delete',
    GET_CONTACT_POINTS: () => 'data_subscription/contact_point',
    GET: () => 'data_subscription',
    GET_NOTIFICATION_POLICY: () => 'data_subscription/notification_policy',
    UPDATE_CONTACT_POINT: (contactPointId: UUID) =>
      urlcat('data_subscription/contact_point/:contactPointId', {
        contactPointId,
      }),
    UPDATE_DATA_SUBSCRIPTION: (contactPointId: UUID) =>
      urlcat('data_subscription/:contactPointId', { contactPointId }),
  },
  DELIVERY_NOTE: {
    GET: (deliveryNoteId: UUID): string =>
      urlcat('asset/delivery_note/:deliveryNoteId', { deliveryNoteId }),
    GET_ALL: () => 'asset/delivery_note/search',
    GET_ALL_ARTICLE_TEMPLATES: () => 'article_master/all',
    GET_ANALYTICS: () => 'analytics',
    GET_ANALYTICS_EXPORT: (requestId: UUID) =>
      urlcat('analytics/report/export/:requestId', { requestId }),
    GET_ASSET_EXPORT: (requestId: UUID) =>
      urlcat('asset/delivery_note/export/:requestId', { requestId }),
    GET_ATTACHMENT: (chainId: UUID, digest: string): string =>
      urlcat('asset/delivery_note/:chainId/attachment', { chainId, digest }),
    GET_CHAINS: (): string => 'asset/delivery_note/ids/chains',
    GET_NOTES_BY_IDS: () => 'asset/delivery_note/ids',
    GET_FILTER_SUGGESTIONS: () => 'analytics/suggestions',
    GET_HANDWRITTEN_SIGNATURE: (chainId: UUID): string =>
      urlcat('asset/delivery_note/chain/:chainId/handwritten_signature', {
        chainId,
      }),
    GET_PDF_EXPORT: (requestId: UUID) =>
      urlcat('asset/delivery_note/export/pdf/:requestId', { requestId }),
    GET_REPORT: () => 'analytics/report',
    GET_SEARCH_COUNT: () => 'asset/delivery_note/search/count',
    GET_SHARED_WITH_USERS: (deliveryNoteId: UUID) =>
      urlcat('asset/:deliveryNoteId/share', { deliveryNoteId }),
    GET_SYNC_GATEWAY_CHANGES: (since: number) =>
      urlcat('_changes', {
        feed: 'longpoll',
        include_docs: true,
        since,
      }), // endpoint uses query parameters in snake case
    GET_TEMPLATES: () => 'dln_template',
    CREATE_TEMPLATE: () => 'dln_template',
    UPDATE_TEMPLATE: () => 'dln_template',
    DELETE_TEMPLATE: () => 'dln_template/delete',
    CREATE: () => 'asset/manual',
    REQUEST_ANALYTICS_EXPORT: () => 'analytics/report/export/request',
    REQUEST_ASSET_EXPORT: () => 'asset/delivery_note/export/request',
    REQUEST_PDF_EXPORT: () => 'asset/delivery_note/export/pdf/request',
    SHARE: (assetId: UUID) => urlcat('asset/:assetId/share', { assetId }),
    UPDATE_DIRECT_MAPPING: (deliveryNoteId: UUID) =>
      urlcat('asset/:deliveryNoteId/direct_mapping', { deliveryNoteId }),
    UPDATE_ARTICLE_TEMPLATE: () => 'article_master',
    VALIDATE: () => 'asset/validate',
  },
  IGNORED_ARTICLES: {
    CREATE: () => 'invoice_verification/ignored_article',
    DELETE: (ignoredArticleId: UUID) =>
      urlcat('invoice_verification/ignored_article/:ignoredArticleId', {
        ignoredArticleId,
      }),
    GET_ALL: () => 'invoice/ignored_article',
    UPDATE: (ignoredArticleId: UUID) =>
      urlcat('invoice_verification/ignored_article/:ignoredArticleId', {
        ignoredArticleId,
      }),
  },
  INVOICE: {
    GET_ALL: () => 'asset/invoice',
    GET: (invoiceId: UUID) => urlcat('asset/invoice/:invoiceId', { invoiceId }),
  },
  ORGANIZATIONAL_UNIT: {
    ADD_TO: (organizationalUnitId: UUID, entityType: EntityType): string =>
      urlcat('access_control/org_unit/:organizationalUnitId/:entityType', {
        entityType,
        organizationalUnitId,
      }),
    CREATE: () => 'access_control/org_unit',
    DELETE: (organizationalUnitId: UUID): string =>
      urlcat('access_control/org_unit/:organizationalUnitId', {
        organizationalUnitId,
      }),
    DELETE_FROM: (
      organizationalUnitId: UUID,
      entityType: EntityType,
      entityId: UUID,
    ): string =>
      urlcat(
        'access_control/org_unit/:organizationalUnitId/:entityType/:entityId',
        {
          entityId,
          entityType,
          organizationalUnitId,
        },
      ),
    GET: (organizationalUnitId: UUID): string =>
      urlcat('access_control/org_unit/:organizationalUnitId', {
        organizationalUnitId,
      }),
    GET_ALL: () => 'access_control/org_unit/test_all',
    UPDATE: (organizationalUnitId: UUID): string =>
      urlcat('access_control/org_unit/:organizationalUnitId', {
        organizationalUnitId,
      }),
  },
  PDF_TEMPLATES: {
    CREATE: () => 'pdf_template',
    CREATE_TEST_PDF_TEMPLATE: () => 'asset/dev/request_test_pdf_template',
    DELETE: (templateId: UUID) =>
      urlcat('pdf_template/:templateId', { templateId }),
    GET: (templateId: UUID) =>
      urlcat('pdf_template/:templateId', { templateId }),
    GET_ALL: () => 'pdf_template',
    GET_TEST_PDF_TEMPLATE: () => 'asset/dev/get_test_pdf_template',
  },
  PERMISSION: {
    CREATE: (
      subjectType: Extract<EntityType, 'user' | 'user_group'>,
      subjectId: UUID,
      targetType: EntityType,
      targetId: UUID,
    ): string =>
      urlcat(
        'access_control/granted_permissions/:subjectType/:subjectId/:targetType/:targetId',
        {
          subjectId,
          subjectType,
          targetId,
          targetType,
        },
      ),
    DELETE: (permissionGrantId: UUID): string =>
      urlcat('access_control/granted_permissions/:permissionGrantId', {
        permissionGrantId,
      }),
    UPDATE: (permissionGrantId: UUID): string =>
      urlcat('access_control/granted_permissions/:permissionGrantId', {
        permissionGrantId,
      }),
  },
  SIGNATURE_FIELD: {
    CREATE: () => 'signature_field',
    DELETE: (signatureFieldId: UUID) =>
      urlcat('signature_field/:signatureFieldId', { signatureFieldId }),
    GET_ALL: () => 'signature_field/all',
    UPDATE: (signatureFieldId: UUID) =>
      urlcat('signature_field/:signatureFieldId', { signatureFieldId }),
  },
  SITE: {
    BLACKLIST_SITE_IDENTIFIER: (siteId: UUID, identifierUuid: UUID) =>
      urlcat('site/:siteId/:identifierUuid', { siteId, identifierUuid }),
    COUNT_AFFECTED_DLNS: (supplierId: UUID) =>
      urlcat('site/identifier/:supplierId/affected_dlns/count', { supplierId }),
    CREATE: () => 'site',
    CREATE_SITES_AND_COST_CENTERS_VIA_CSV: () => 'admin/site_cc_csv',
    CREATE_VIA_CSV: () => 'site/import_csv',
    DELETE_IDENTIFIER: (siteId: UUID, identifierUuid: UUID) =>
      urlcat('site/:siteId/identifier/:identifierUuid', {
        siteId,
        identifierUuid,
      }),
    GET: (siteId: UUID): string => urlcat('site/:siteId', { siteId }),
    GET_ALL: () => 'site',
    GET_BULK: () => 'site/query/bulk',
    GET_IDENTIFIER_PROPOSALS: () => 'site/identifier/proposals',
    GET_ALL_LEGACY: () => 'site/all',
    IDENTIFIER_FOR_SUPPLIER: (siteId: UUID) =>
      urlcat('site/:siteId/identifier', { siteId }),
    PROPOSAL_IDENTIFIER: (siteId: UUID) =>
      urlcat('site/:siteId/identifier/proposal', { siteId }),
    UPDATE: (siteId: UUID): string => urlcat('site/:siteId', { siteId }),
    UPDATE_ACCOUNTING_REFERENCES: (siteId: UUID) =>
      urlcat('site/:siteId/accounting_reference', { siteId }),
    UPDATE_COST_CENTERS: (siteId: UUID): string =>
      urlcat('site/:siteId/accounting_reference', { siteId }),
    UPDATE_IDENTIFIER: () => 'site/identifier',
  },
  USER: {
    COMPANY_ACCOUNT: () => 'user/company_account',
    CREATE: (): string => 'admin/users',
    CREATE_VIA_CSV: () => 'admin/users_csv',
    DELETE_PROFILE_PICTURE: () => 'user/profile_picture',
    GET: (userId: UUID): string => urlcat('user/:userId', { userId }),
    GET_ALL: () => 'user',
    GET_BULK: () => 'user/query/bulk',
    GET_COMPANY: (userId: UUID): string =>
      urlcat('user/:userId/company', { userId }),
    GET_DATA: (includeCompanyInfo: boolean): string =>
      urlcat('user/data', { include_company_info: includeCompanyInfo }), // endpoint uses query parameter 'include_company_info'!
    GET_PROFILE_PICTURE: () => 'user/profile_picture',
    GET_PUSH_NOTIFICATION_SETTINGS: (userId: UUID): string =>
      urlcat('user/notification/settings', { user_id: userId }), // endpoint uses query parameter 'user_id'!
    GET_SETTINGS: (userId: UUID): string =>
      urlcat('admin/user/:userId/settings', { userId }),
    GET_SIGNATURE_ROLES: (userId: UUID): string =>
      urlcat('user/:userId/signature_roles', { userId }),
    UPDATE: (userId: UUID): string => urlcat('admin/users/:userId', { userId }), // endpoint uses query parameter 'user_id'!
    UPDATE_USER_SETTINGS: () => 'user/settings',
    UPDATE_NAME: () => 'user/name',
    UPDATE_POSITION: () => 'user/position',
    UPDATE_PROFILE_PICTURE: () => 'user/profile_picture',
    UPDATE_PUSH_NOTIFICATION_SETTINGS: (userId: UUID): string =>
      urlcat('user/notification/settings', { user_id: userId }), // endpoint uses query parameter 'user_id'!
    UPDATE_SETTINGS: (userId: UUID): string =>
      urlcat('admin/user/:userId/settings', { userId }),
    UPDATE_SIGNATURE_ROLES: (userId: UUID): string =>
      urlcat('user/:userId/signature_roles', { userId }),
  },
  USER_GROUP: {
    ADD_TO: (
      userGroupId: UUID,
      memberType: Extract<EntityType, 'user' | 'user_group'>,
    ): string =>
      urlcat('access_control/user_group/:userGroupId/:memberType', {
        memberType,
        userGroupId,
      }),
    CREATE: (): string => 'access_control/user_group',
    DELETE_FROM: (
      userGroupId: UUID,
      memberType: Extract<EntityType, 'user' | 'user_group'>,
      memberId: UUID,
    ): string =>
      urlcat('access_control/user_group/:userGroupId/:memberType/:memberId', {
        memberId,
        memberType,
        userGroupId,
      }),
    GET: (userGroupId: UUID): string =>
      urlcat('access_control/user_group/:userGroupId', { userGroupId }),
    GET_ALL: () => 'access_control/user_group/test_all',
    UPDATE: (userGroupId: UUID): string =>
      urlcat('access_control/user_group/:userGroupId', { userGroupId }),
  },
  VEHICLE: {
    CREATE: () => 'vehicle',
    GET: (vehicleId: UUID): string =>
      urlcat('vehicle/:vehicleId', { vehicleId }),
    GET_ALL: () => 'vehicle',
    UPDATE: (vehicleId: UUID): string =>
      urlcat('vehicle/:vehicleId', { vehicleId }),
  },
} as const;
