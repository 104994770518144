import { ArrowDropDown as ArrowDropDownIcon } from '@mui/icons-material';
import { Chip } from '@mui/material';
import clsx from 'clsx';
import {
  type CSSProperties,
  type ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { components, type GroupBase, type MenuListProps } from 'react-select';
import {
  AsyncPaginate,
  type LoadOptions,
  type ShouldLoadMore,
  wrapMenuList,
} from 'react-select-async-paginate';

import { EMPTY_DROPDOWN_OPTION } from '~/constants/select';

import { promiseAllThrottled } from '~/utils/promise';

import { Spinner } from '~/ui/atoms/Spinner';

export type Option<T = any> = {
  value: string;
  label: string;
  data: T;
};

export type SelectServerDrivenProps<T> = {
  readonly debounceTime?: number;
  readonly formatOptionLabel?: (
    option: Option<T>,
    formatOptionLabelMeta: any,
  ) => ReactNode;
  readonly getItemData: (value: string) => Promise<T | undefined>;
  readonly getOptionLabel: (item: T) => string;
  readonly isClearable?: boolean;
  readonly isDisabled?: boolean;
  readonly isItemSelectionAllowed: () => boolean;
  readonly isMultiSelect?: boolean;
  readonly loadOptions: LoadOptions<
    Option<T>,
    GroupBase<Option<T>>,
    { page: number; filterActive?: boolean | undefined }
  >;
  readonly onChange: {
    (value: string[], data: T[]): void;
    (value: string, data: T): void;
  };
  readonly placeholder?: string;
  readonly value: string | string[];
  readonly filterActive?: boolean;
} & ComponentStyling;

const hideComponent = () => null;

// Always return the value as an array.
const ensureArray = (value: string | string[]) =>
  Array.isArray(value) ? value : value ? [value] : [];

/**
 * A function used to determine if the select should try to load more options if available.
 * Made it so it will return true if the user is 2/3 of the way down
 * This looks like a good compromise for loading additional options without adding too much delay.
 *
 * @param scrollHeight - The height of the scrollable container
 * @param clientHeight - The height of the visible area of the scrollable container
 * @param scrollTop - The distance of the scrollable container from the top of the page
 * @returns Whether the load more button should be shown
 */
const shouldLoadMore: ShouldLoadMore = (
  scrollHeight,
  clientHeight,
  scrollTop,
) => {
  const bottomBorder = ((scrollHeight - clientHeight) * 2) / 3;

  return bottomBorder < scrollTop;
};

const CustomMenuList = (
  props: MenuListProps & {
    readonly isLoading: boolean;
    readonly options: any[];
  },
) => {
  const { isLoading, options, children } = props;

  return (
    <components.MenuList {...props}>
      {children}
      {isLoading && options.length > 0 && <Spinner className="py-4" />}
    </components.MenuList>
  );
};

/**
 * SelectServerDriven is a customizable, server-driven select component that supports both single and multi-select modes.
 * It uses react-select-async-paginate for efficient loading of paginated data from a server.
 *
 * @template T - The type of the item data returned by getItemData
 *
 * @param props - The component props
 * @param props.className - Additional CSS class for the select component
 * @param props.debounceTime - Debounce time for search input in milliseconds (default: 300)
 * @param props.formatOptionLabel - Function to format the option label
 * @param props.getItemData - Function to fetch item data for a given value
 * @param props.getOptionLabel - Function to get the label string for an item
 * @param props.isClearable - Whether the select allows clearing the selection (default: true)
 * @param props.isDisabled - Whether the select is disabled (default: false)
 * @param props.isItemSelectionAllowed - Function to determine if item selection is allowed
 * @param props.isMultiSelect - Whether the select allows multiple selections (default: false)
 * @param props.loadOptions - Function to load options from the server
 * @param props.onChange - Callback function when selection changes
 * @param props.placeholder - Placeholder text for the select (default: 'Element auswählen')
 * @param props.style - Additional inline styles for the select component
 * @param props.value - Current selected value(s)
 *
 * @returns JSX.Element
 */
export const SelectServerDriven = <T,>({
  className,
  debounceTime = 300,
  formatOptionLabel,
  getItemData,
  getOptionLabel,
  isItemSelectionAllowed = () => true,
  isClearable = true,
  isDisabled = false,
  isMultiSelect = false,
  loadOptions,
  onChange,
  placeholder = 'Element auswählen',
  style,
  value,
  filterActive = true,
}: SelectServerDrivenProps<T>): JSX.Element => {
  const [selectedItems, setSelectedItems] = useState<Array<Option<T>>>([]);

  const [error, setError] = useState<string | undefined>(undefined);

  const getSelectedItems = useCallback(
    async (value, getItemData, getOptionLabel) => {
      try {
        const items = await promiseAllThrottled(
          ensureArray(value)
            .filter(Boolean) // Only filter out falsy values
            .map(async (value_) => {
              // Special handling for 'empty' option
              if (value_ === EMPTY_DROPDOWN_OPTION) {
                return {
                  data: {
                    id: EMPTY_DROPDOWN_OPTION,
                    name: EMPTY_DROPDOWN_OPTION,
                  },
                  label: EMPTY_DROPDOWN_OPTION,
                  value: EMPTY_DROPDOWN_OPTION,
                };
              }

              const item = await getItemData(value_);

              return item
                ? {
                    data: item,
                    label: getOptionLabel(item),
                    value: value_,
                  }
                : null;
            }),
        );

        setSelectedItems(
          items.filter((item): item is Option<T> => item != null),
        );
      } catch (error) {
        setError('Failed to fetch selected items. Please try again.');
        console.error('Error fetching selected items:', error);
      }
    },
    [JSON.stringify(value), getItemData, getOptionLabel],
  );

  useEffect(() => {
    if (!value) {
      return;
    }

    void getSelectedItems(value, getItemData, getOptionLabel);
  }, [value, getItemData, getOptionLabel, getSelectedItems]);

  const MultiValue = ({ data }: { readonly data: Option<T> }) => (
    <Chip
      className="mr-1 first:-ml-1.5"
      label={data.label}
      onDelete={() => {
        handleChange(selectedItems.filter(({ value }) => value !== data.value));
      }}
    />
  );

  const DropdownIndicator = ({ selectProps }) => (
    <div
      className={clsx(
        'flex size-8 cursor-pointer items-center justify-center transition-transform',
        {
          'rotate-0': !selectProps.menuIsOpen,
          'rotate-180': selectProps.menuIsOpen,
        },
      )}
    >
      <ArrowDropDownIcon />
    </div>
  );

  const handleChange = useCallback(
    (newValue: Option<T> | Array<Option<T>> | undefined) => {
      const newSelectedItems = ensureArray(newValue);

      setSelectedItems(newSelectedItems);

      const newValues = newSelectedItems.map(({ value }) => value);
      const newData = newSelectedItems.map(({ data }) => data);

      onChange(
        isMultiSelect ? newValues : (newValues[0] ?? ''),
        isMultiSelect ? newData : newData[0],
      );
    },
    [onChange, isMultiSelect],
  );

  const handleLoadOptions: typeof loadOptions = async (...arguments_) => {
    try {
      return await loadOptions(...arguments_);
    } catch (error) {
      setError('Failed to load options. Please try again.');
      console.error('Error loading options:', error);

      return {
        hasMore: false,
        options: [],
      };
    }
  };

  const additional = {
    filterActive,
    page: 1,
  };

  const memoizedStyles = useMemo(
    () => ({
      control: (provided: CSSProperties) => ({
        ...provided,
        minHeight: '2.5rem',
      }),
      menu: (provided: CSSProperties) => ({
        ...provided,
        margin: 0,
      }),
      menuPortal: (provided: CSSProperties) => ({
        ...provided,
        zIndex: 1500,
      }),
      valueContainer: (provided: CSSProperties) => ({
        ...provided,
        rowGap: '0.25rem',
      }),
      ...style,
    }),
    [style],
  );

  return (
    <>
      <AsyncPaginate<Option<T>, GroupBase<Option<T>>, { page: number }>
        additional={additional}
        className={className}
        components={{
          DropdownIndicator,
          IndicatorSeparator: hideComponent,
          MultiValue,
          MenuList: wrapMenuList((props) => <CustomMenuList {...props} />),
        }}
        debounceTimeout={debounceTime}
        formatOptionLabel={formatOptionLabel}
        isClearable={isClearable}
        isDisabled={isDisabled || !isItemSelectionAllowed()}
        isMulti={isMultiSelect}
        loadOptions={handleLoadOptions}
        loadingMessage={() => 'Lade Daten...'}
        menuPortalTarget={document.body}
        noOptionsMessage={() => 'Keine passenden Daten gefunden'}
        placeholder={placeholder}
        shouldLoadMore={shouldLoadMore}
        styles={memoizedStyles}
        value={isMultiSelect ? selectedItems : selectedItems[0] || null}
        onChange={handleChange}
      />
      {error && <div className="mt-1 text-red-500">{error}</div>}
    </>
  );
};
