import { validate as uuidvalidate } from 'uuid';

import FileReaderUtils from '~/utils/fileReaderUtils';
import { isValidNumber } from '~/utils/number';
import { promiseHandler } from '~/utils/promiseHandler';

import { COLUMN_SEPARATOR, DATA_TYPE, VALIDATION_ERROR } from './constants';
import { type AllowedColumns, type CSVValidatorOptions } from './types';

export const CSVValidatorObject = {
  COLUMN_SEPARATOR,
  DATA_TYPE,
  VALIDATION_ERROR,

  fileColumns: [] as string[],
  fileRows: [] as string[],
  allowedColumns: [] as AllowedColumns,

  /**
   * Initializes the CSV file by reading its contents.
   * @param {CSVValidatorOptions} options - Contains `allowedColumns` (Array of allowed column definitions) and `file` (File object).
   */
  async initFile({ file, allowedColumns }: CSVValidatorOptions) {
    const [string, error] = await promiseHandler(
      FileReaderUtils.readFileAsTextAsync(file),
    );

    if (error) {
      throw error;
    }

    // Split the content by all types of new line delimiters
    let rows = string.split('\r\n');
    if (rows.length === 1) {
      rows = string.split('\n');
    }

    if (rows.length === 1) {
      rows = string.split('\r');
    }

    this.fileColumns = rows[0]
      .split(COLUMN_SEPARATOR)
      .map((column: string) => column.trim());
    this.fileRows = rows.slice(1); // All rows except the header row
    this.allowedColumns = allowedColumns;
  },

  /**
   * Validates the entire CSV file: columns and rows.
   * @returns {[boolean | null, string | null]} - Returns a tuple: [Validation Success (true if valid, null if invalid), Error Message (if any)].
   */
  validate() {
    const [, validationError] = this.validateColumns();
    if (validationError) {
      return [null, validationError];
    }

    const [, validationError2] = this.validateRows();
    if (validationError2) {
      return [null, validationError2];
    }

    return [true, null];
  },

  /**
   * Validates if all columns in the CSV file match the allowed columns.
   * @returns {[boolean | null, string | null]} - Returns a tuple: [Validation Success (true if valid, null if invalid), Error Message (if any)].
   */
  validateColumns() {
    for (const column of this.fileColumns) {
      const allowedColumn = this.allowedColumns.find(
        (allowedColumn: { NAME: string | undefined }) =>
          allowedColumn.NAME === column,
      );

      if (!allowedColumn) {
        return [null, `${VALIDATION_ERROR.UNKNOWN_COLUMN} ${column}`];
      }
    }

    return [true, null];
  },

  /**
   * Validates all rows in the CSV file.
   * @returns {[boolean | null, string | null]} - Returns a tuple: [Validation Success (true if valid, null if invalid), Error Message (if any)].
   */
  validateRows() {
    for (const fileRow of this.fileRows) {
      const [, validationError] = this.validateRow(fileRow);

      if (validationError) {
        return [null, validationError];
      }
    }

    return [true, null];
  },

  /**
   * Validates each row in the CSV file.
   * @param {string} fileRow - A single row from the CSV file as a string.
   * @returns {[boolean | null, string | null]} - Returns a tuple: [Validation Success (true if valid, null if invalid), Error Message (if any)].
   */
  validateRow(fileRow: string) {
    const fileRowArray = this.parseCSVRow(fileRow);

    for (const [index, element] of fileRowArray.entries()) {
      if (!this.fileColumns[index]) {
        return [null, VALIDATION_ERROR.TOO_MANY_VALUES];
      }

      const [, validationError] = this.validateValue(
        element,
        this.fileColumns[index],
      );
      if (validationError) {
        return [null, validationError];
      }
    }

    return [true, null];
  },

  /**
   * Validates each individual value in a CSV row.
   * @param {string} value - The value from the row to validate.
   * @param {string} fileColumn - The column name for this value.
   * @returns {[boolean | null, string | null]} - Returns a tuple: [Validation Success (true if valid, null if invalid), Error Message (if any)].
   */
  validateValue(value: string, fileColumn: string) {
    const allowedColumn = this.allowedColumns.find(
      (allowedColumn) => allowedColumn.NAME === fileColumn,
    );

    switch (allowedColumn?.DATA_TYPE) {
      case this.DATA_TYPE.TEXT: {
        // Nothing to validate here for text.
        return [true, null];
      }

      case this.DATA_TYPE.NUMBER: {
        if (!isValidNumber(Number(value))) {
          return [null, `${VALIDATION_ERROR.INVALID_NUMBER} ${value}`];
        }

        break;
      }

      case this.DATA_TYPE.BOOLEAN: {
        if (value !== 'true' && value !== 'false') {
          return [null, `${VALIDATION_ERROR.INVALID_BOOLEAN} ${value}`];
        }

        break;
      }

      case this.DATA_TYPE.UUID: {
        if (value && !uuidvalidate(value)) {
          return [null, `${VALIDATION_ERROR.INVALID_UUID} ${value}`];
        }

        break;
      }

      case this.DATA_TYPE.LIST: {
        break;
      }

      case this.DATA_TYPE.UUID_LIST: {
        break;
      }

      default: {
        break;
      }
    }

    return [true, null];
  },

  /**
   * Parses a CSV row to handle commas inside quotes.
   * @param {string} csvRow - The raw CSV row as a string.
   * @returns {string[]} - An array of values extracted from the CSV row.
   */
  parseCSVRow(csvRow: string): string[] {
    const values: string[] = [];
    let current = '';
    let insideQuotes = false;

    for (const char of csvRow) {
      if (char === '"') {
        insideQuotes = !insideQuotes;
      } else if (char === ',' && !insideQuotes) {
        values.push(current);
        current = '';
      } else {
        current += char;
      }
    }

    values.push(current);

    return values;
  },
};
