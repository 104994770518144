/* eslint-disable react/require-default-props */
import MUITooltip from '@mui/material/Tooltip';
import clsx from 'clsx';
import { forwardRef, type PropsWithChildren } from 'react';

type P = PropsWithChildren<{
  readonly colorScheme?: 'light' | 'dark';
  readonly enterDelay?: number;
  readonly enterNextDelay?: number;
  readonly onMouseEnter?: () => void;
  readonly placement?: 'top' | 'bottom' | 'left' | 'right';
  readonly shouldWrapChildren?: boolean;
  readonly title?: string;
  readonly width?: 'xl' | '4xl';
}> &
  ComponentStyling;

export const Tooltip = forwardRef(
  (
    {
      children,
      className,
      colorScheme = 'light',
      enterDelay,
      enterNextDelay,
      onMouseEnter,
      placement = 'top',
      shouldWrapChildren = false,
      style,
      title = '',
      width,
    }: P,
    ref,
  ) => (
    <MUITooltip
      ref={ref}
      enterDelay={enterDelay}
      enterNextDelay={enterNextDelay}
      placement={placement}
      slotProps={{
        popper: {
          className,
        },
        tooltip: {
          className: clsx(
            'max-h-96 text-xs shadow',
            colorScheme === 'dark'
              ? 'bg-gray-900 text-white'
              : 'bg-white text-gray-800',
            {
              'max-w-xl': width === 'xl',
              'max-w-4xl overflow-y-scroll': width === '4xl',
            },
          ),
        },
      }}
      style={style}
      title={title}
      onMouseEnter={onMouseEnter}
    >
      {shouldWrapChildren ? <span>{children}</span> : children}
    </MUITooltip>
  ),
);
