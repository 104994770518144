export const emptyDeliveryNoteFilterGroup = {
  selectedAcceptState: [],
  selectedArticle: [],
  selectedArticleNumber: [],
  selectedCategory: [],
  selectedCostCenter: [],
  selectedCustomFields: [],
  selectedFromSite: [],
  selectedOuId: [],
  selectedPermittedCostCenters: [],
  selectedPermittedToSites: [],
  selectedProcessState: [],
  selectedRecipient: [],
  selectedSettledStatus: [],
  selectedSupplier: [],
  selectedToSiteRecipient: [],
  selectedToSiteSupplier: [],
};
