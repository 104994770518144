import { type DataExchangeExcelDataType } from './useQueryDataExchangeExcelData';

export const queryKeysDataExchange = {
  base: () => 'dataExchange' as const,
  getAllSuppliers: (queryParams) =>
    [queryKeysDataExchange.base(), 'list', queryParams].filter(
      Boolean,
    ) as const,
  getAllLegacy: () => [queryKeysDataExchange.base(), 'list', 'LEGACY'] as const,
  getCompanies: (initDataExchangeCompanies = true) =>
    [
      queryKeysDataExchange.base(),
      'companies',
      { initDataExchangeCompanies },
    ] as const,
  getDataAsExcel: (queryParams: Partial<DataExchangeExcelDataType>) =>
    [queryKeysDataExchange.base(), 'excelData', queryParams] as const,
} as const;
