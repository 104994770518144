import { configureStore } from '@reduxjs/toolkit';

import backdrop from './backdropSlice';
import companyAccount from './companyAccountSlice';
import customFields from './customFieldsSlice';
import dataExchanges from './dataExchangesSlice';
import deliveryNotes from './deliveryNotesSlice';
import filters from './filtersSlice';
import invoices from './invoicesSlice';
import userinfo from './userinfoSlice';

const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
  reducer: {
    backdrop,
    companyAccount,
    customFields,
    dataExchanges,
    deliveryNotes,
    filters,
    invoices,
    userinfo,
  },
});

if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
  globalThis.store = store; // Assuming `store` is your Redux store instance
}

export default store;
