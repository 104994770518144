import {
  useMutation,
  type UseMutationOptions,
  useQueryClient,
} from '@tanstack/react-query';

import { ENDPOINT } from '~/constants/endpoints';

import { vestigasApi } from '~/services/kyClient';

import { Log } from '~/utils/logging';

import { queryKeysUser } from './queryKeys';

export type UserSettingsPayload = Record<string, unknown>;

type UserSettingsResponse = {
  code: string;
  message: string;
  requestId: string;
};

/**
 * Updates user settings.
 *
 * @param {Object} payload - An object containing the user settings to update.
 * @returns {Promise} A promise that resolves with the API response.
 *
 * @see https://app.dev.vestigas.com/redoc#tag/User/operation/update_user_settings_user_settings_post
 */
export const updateCurrentUserSettings = async (
  payload: UserSettingsPayload,
) => {
  try {
    const response = await vestigasApi
      .post(ENDPOINT.USER.UPDATE_USER_SETTINGS(), { json: payload })
      .json<UserSettingsResponse>();

    return response;
  } catch (error) {
    Log.error('Error updating user settings', error);

    throw error; // re-throw error so it can be handled higher up in the callstack.
  }
};

/**
 * Custom hook for updating the user settings of a current user.
 *
 * @returns {Object} An object containing the mutation function and related properties.
 */
export const useMutationUpdateCurrentUserSettings = (
  options?: UseMutationOptions<UserSettingsPayload, Error, UserSettingsPayload>,
) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (payload: UserSettingsPayload) =>
      updateCurrentUserSettings(payload),
    async onError(_error) {
      Log.productAnalyticsEvent(
        'Failed to update user settings',
        Log.FEATURE.USER,
        Log.TYPE.ERROR,
      );
    },
    onSuccess() {
      // Update both the user data and the user actions data.
      queryClient.invalidateQueries({
        queryKey: [queryKeysUser.base(), 'data'],
      });
    },
    ...options,
  });
};
