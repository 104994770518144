import { gridFilteredSortedRowIdsSelector } from '@mui/x-data-grid';
import {
  DataGridPro,
  useGridApiEventHandler,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { debounce } from 'lodash-es';
import {
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSearchParams } from 'react-router';

import { LOADING_STATE } from '~/constants/LoadingState';
import { ComponentTestIds } from '~/constants/test-ids';

import LocalStorageService from '~/services/localStorage.service';

import { sortByKeyValues, subtract, unique } from '~/utils/array';
import DatagridUtils from '~/utils/datagridUtils';
import { Log } from '~/utils/logging';
import { clone } from '~/utils/object';
import { promiseHandler } from '~/utils/promiseHandler';
import { usePersistedUserSettings } from '~/utils/usePersistedUserSettings';

import {
  Footer,
  LoadingState,
  Pagination,
} from '~/ui/molecules/Datagrid/components';

import { GridToolbar } from './GridToolbar';
import { NoRowsOverlay } from './NoRowsOverlay';
import { orderColumns, sizeColumns } from './utils';

export const BasicTable = memo(
  ({
    checkboxSelection,
    className,
    columns,
    defaultHiddenColumns,
    defaultPinnedColumns,
    disableColumnFilter,
    disableColumnReorder,
    excelColumns,
    excelData,
    filterMode,
    filterModel,
    headerSelectionCheckboxProps,
    hideFooter,
    hideHeader,
    hideToolbar,
    initialState,
    isFetching,
    isLoading,
    loading,
    localStorageKey,
    logicOperators,
    multiplePdfDownload,
    noColumnsButton,
    noExportButton,
    noFilterButton,
    onExportCSVFromBackend,
    onExportExcelFromBackend,
    onExportInvoiceExcel,
    onFilterModelChange,
    onMapDirectDeliveryNote,
    onMultiPermissionGrantEdit,
    onPdfExport,
    onRequestDeliveryNoteSignature,
    onRowClick,
    onRowRightClick,
    onRowSelectionModelChange: propsOnRowSelectionModelChange,
    onShareDeliveryNote,
    onStateChange: propsOnStateChange,
    pageSize = 100,
    paginationMode,
    paginationText,
    pinnedColumnsCookieId,
    productAnalyticsFeature,
    rows,
    rowSelectionModel,
    selectAllRowsCount,
    sortingMode,
    testId,
    userSettingsKey, // Use instead of localStorageKey if you want to persist settings on the server only and not in localStorage
    ...otherProps
  }) => {
    // Allow passing in an apiRef and set one internally if not provided
    const apiRef = otherProps.apiRef ?? useGridApiRef();

    const { getUserSettings, persistUserSettings } = usePersistedUserSettings({
      debounceTime: 1000,
      settingsKey: userSettingsKey ?? localStorageKey,
      useLocalStorage: !userSettingsKey,
    });

    const [columnOrderModel, setColumnOrderModel] = useState([]);
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({});
    const [columnWidthModel, setColumnWidthModel] = useState({});
    const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState(
      [],
    );
    const isScrolledRef = useRef(false);
    const [filteredSortedRows, setFilteredSortedRows] = useState([]);
    const [pinnedColumns, setPinnedColumns] = useState(
      LocalStorageService.getObjectFromLocalStorage(pinnedColumnsCookieId) ??
        defaultPinnedColumns ?? { left: [], right: [] },
    );
    const [searchParams, setSearchParams] = useSearchParams();

    // If we have a scrollX in the search params, scroll to it after the component mounts
    useLayoutEffect(() => {
      const scrollX = searchParams.get('scrollX');
      let scrollTimeout;
      if (!isScrolledRef.current && scrollX && apiRef.current.scroll) {
        scrollTimeout = setTimeout(() => {
          if (rows.length > 0) {
            isScrolledRef.current = true;
            apiRef.current.scroll({ top: scrollX });
          }
        }, 100);
      }

      return () => {
        if (scrollTimeout) {
          clearTimeout(scrollTimeout);
        }
      };
    }, [apiRef, rows]);

    useLayoutEffect(() => {
      // Load initial state
      const userSettings = getUserSettings();

      const newState = {
        columnOrderModel: userSettings?.columnOrderModel ?? [],
        columnVisibilityModel: userSettings?.columnVisibilityModel ?? {},
        columnWidthModel: userSettings?.columnWidthModel ?? {},
      };

      newState.columnVisibilityModel = hideDefaultColumns(
        newState.columnVisibilityModel,
      );

      setColumnOrderModel(newState.columnOrderModel);
      setColumnVisibilityModel(newState.columnVisibilityModel);
      setColumnWidthModel(newState.columnWidthModel);
    }, [localStorageKey, userSettingsKey, getUserSettings]);

    useEffect(() => {
      setColumnVisibilityModel((previousModel) =>
        hideDefaultColumns(previousModel),
      );
    }, [defaultHiddenColumns]);

    const hideDefaultColumns = useCallback(
      (visibilityModel) => {
        const newColumnVisibilityModel = clone(visibilityModel);

        if (defaultHiddenColumns) {
          for (const column of defaultHiddenColumns) {
            if (newColumnVisibilityModel[column] === undefined) {
              newColumnVisibilityModel[column] = false;
            }
          }
        }

        return newColumnVisibilityModel;
      },
      [defaultHiddenColumns],
    );

    const orderedAndSizedColumns = useMemo(() => {
      const columnOrderModel = getUserSettings()?.columnOrderModel ?? [];

      const orderedColumns = orderColumns(
        columns,
        columnOrderModel,
        disableColumnReorder,
      );

      return sizeColumns(orderedColumns, columnWidthModel);
    }, [columns, columnWidthModel, disableColumnReorder, getUserSettings]);

    const hiddenHeaderProps = hideHeader
      ? {
          columnHeaderHeight: 0,
          sx: {
            '& .MuiDataGrid-columnHeaders': {
              display: 'none !important',
            },
            '& .MuiDataGrid-toolbarContainer': {
              display: 'none !important',
              height: '0 !important',
              padding: '0 !important',
              visibility: 'hidden !important',
            },
          },
        }
      : null;

    const getPinnedColumns = useCallback(() => {
      if (pinnedColumns === null) {
        return (
          defaultPinnedColumns ?? {
            left: [],
            right: [],
          }
        );
      }

      return pinnedColumns;
    }, [pinnedColumns, defaultPinnedColumns]);

    const memoizedExcelData = useMemo(() => {
      if (!excelData?.length) {
        return [];
      }

      if (!filteredSortedRows?.length) {
        return excelData;
      }

      return sortByKeyValues(excelData, filteredSortedRows, 'id');
    }, [excelData, filteredSortedRows]);

    const getToolbar = useCallback(() => {
      if (hideToolbar) {
        return null;
      }

      return (
        <GridToolbar
          apiRef={apiRef}
          excelColumns={excelColumns}
          excelData={memoizedExcelData}
          multiplePdfDownload={multiplePdfDownload}
          noColumnsButton={noColumnsButton}
          noExportButton={noExportButton}
          noFilterButton={noFilterButton}
          productAnalyticsFeature={productAnalyticsFeature}
          onExportCSVFromBackend={onExportCSVFromBackend}
          onExportExcelFromBackend={onExportExcelFromBackend}
          onExportInvoiceExcel={onExportInvoiceExcel}
          onMapDirectDeliveryNote={onMapDirectDeliveryNote}
          onMultiPermissionGrantEdit={onMultiPermissionGrantEdit}
          onPdfExport={onPdfExport}
          onRequestDeliveryNoteSignature={onRequestDeliveryNoteSignature}
          onShareDeliveryNote={onShareDeliveryNote}
        />
      );
    }, [
      apiRef,
      excelColumns,
      memoizedExcelData,
      hideToolbar,
      multiplePdfDownload,
      noColumnsButton,
      noExportButton,
      noFilterButton,
      onExportCSVFromBackend,
      onExportExcelFromBackend,
      onExportInvoiceExcel,
      onMapDirectDeliveryNote,
      onMultiPermissionGrantEdit,
      onPdfExport,
      onRequestDeliveryNoteSignature,
      onShareDeliveryNote,
      productAnalyticsFeature,
    ]);

    const onColumnVisibilityModelChange = useCallback(
      (newModel) => {
        Log.info(
          'Change column visibility',
          {
            from: columnVisibilityModel,
            to: newModel,
          },
          Log.BREADCRUMB.FILTER_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change column visibility',
          Log.FEATURE.BASIC_TABLE,
        );

        setColumnVisibilityModel(newModel);
        persistUserSettings({
          columnVisibilityModel: newModel,
          columnWidthModel,
          columnOrderModel,
        });
      },
      [
        columnOrderModel,
        columnVisibilityModel,
        columnWidthModel,
        persistUserSettings,
      ],
    );

    const onColumnWidthChange = useCallback(
      (event) => {
        const newColumnWidthModel = clone(columnWidthModel);
        newColumnWidthModel[event.colDef.field] = event.colDef.width;

        Log.info(
          'Change column width',
          {
            from: columnWidthModel,
            to: newColumnWidthModel,
          },
          Log.BREADCRUMB.FILTER_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change column width',
          Log.FEATURE.BASIC_TABLE,
        );

        setColumnWidthModel(newColumnWidthModel);
        persistUserSettings({
          columnOrderModel,
          columnVisibilityModel,
          columnWidthModel: newColumnWidthModel,
        });
      },
      [
        columnOrderModel,
        columnVisibilityModel,
        columnWidthModel,
        persistUserSettings,
      ],
    );

    const onColumnOrderChange = useCallback(
      async (newColumnOrderModel) => {
        // Don't persist column order if we don't have all columns yet
        if (!columns?.length) {
          return;
        }

        const userSettings = getUserSettings();
        const storedColumnOrderModel = userSettings?.columnOrderModel ?? [];

        // Validate that new order contains all current columns
        const currentFields = new Set(columns.map(({ field }) => field));
        const newOrderFields = new Set(newColumnOrderModel);
        const isValidOrder = [...currentFields].every((field) =>
          newOrderFields.has(field),
        );

        if (!isValidOrder) {
          return;
        }

        // Detect changes in content and order
        const hasChanges =
          JSON.stringify(storedColumnOrderModel) !==
          JSON.stringify(newColumnOrderModel);

        if (newColumnOrderModel && !hasChanges) {
          return;
        }

        setColumnOrderModel(newColumnOrderModel);
        persistUserSettings({
          columnOrderModel: newColumnOrderModel,
          columnVisibilityModel,
          columnWidthModel,
        });

        Log.info(
          'Change column order',
          {
            from: storedColumnOrderModel,
            to: newColumnOrderModel,
          },
          Log.BREADCRUMB.FILTER_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change column order',
          Log.FEATURE.BASIC_TABLE,
        );
      },
      [
        columns,
        columnVisibilityModel,
        columnWidthModel,
        getUserSettings,
        persistUserSettings,
      ],
    );

    const onStateChange = useCallback(
      (state) => {
        onColumnOrderChange(state.columns.orderedFields);

        if (propsOnStateChange) {
          propsOnStateChange(state);
        }

        if (!excelData) {
          return;
        }

        const newFilteredSortedRows = unique(
          gridFilteredSortedRowIdsSelector(state, apiRef.current.state),
        );

        if (
          JSON.stringify(filteredSortedRows) ===
          JSON.stringify(newFilteredSortedRows)
        ) {
          return;
        }

        setFilteredSortedRows(newFilteredSortedRows);
      },
      [onColumnOrderChange, propsOnStateChange, excelData, filteredSortedRows],
    );

    const onRowSelectionModelChange = useCallback(
      (event) => propsOnRowSelectionModelChange(event),
      [propsOnRowSelectionModelChange],
    );

    const onPinnedColumnsChange = useCallback(
      (event) => {
        Log.info(
          'Change pinned columns',
          {
            from: pinnedColumns,
            to: event,
          },
          Log.BREADCRUMB.FILTER_CHANGE.KEY,
        );
        Log.productAnalyticsEvent(
          'Change pinned columns',
          Log.FEATURE.BASIC_TABLE,
        );

        setPinnedColumns(event);

        LocalStorageService.setObjectAsLocalStorage(
          pinnedColumnsCookieId,
          event,
        );
      },
      [pinnedColumns, pinnedColumnsCookieId],
    );

    // Created an adapter for onRowClick to save the scroll position to the search params before running the passed in onRowClick
    const onRowClickHandler = useCallback(
      (event) => {
        if (!onRowClick) {
          return;
        }

        const scrollPosition = apiRef.current.getScrollPosition();
        setSearchParams(
          (previous) => {
            previous.set('scrollX', scrollPosition.top);
            return previous;
          },
          { replace: true },
        );

        onRowClick(event);
      },
      [onRowClick, apiRef],
    );

    const slots = useMemo(
      () => ({
        footer: hideFooter ? () => null : Footer,
        loadingOverlay: () => <LoadingState />,
        noRowsOverlay: () => <NoRowsOverlay loading={loading} />,
        pagination: Pagination,
        toolbar: () => getToolbar(),
      }),
      [getToolbar, hideFooter, loading],
    );

    const slotProps = useMemo(
      () => ({
        ...(logicOperators && {
          filterPanel: {
            logicOperators,
          },
        }),
        footer: {
          isFetching: isFetching ?? false,
          paginationText,
          selectedRowCount: selectAllRowsCount,
        },
        pagination: {
          'data-testid': ComponentTestIds.TABLE.PAGINATION,
          isFetching: isFetching ?? false,
          paginationText,
        },
        row: {
          onContextMenu: onRowRightClick,
          style: {
            cursor: onRowRightClick
              ? 'context-menu'
              : onRowClick
                ? 'pointer'
                : 'auto',
          },
        },
      }),
      [
        isFetching,
        logicOperators,
        onRowClick,
        onRowRightClick,
        paginationText,
        selectAllRowsCount,
      ],
    );

    // Ensure detailPanelExpandedRowIds is an array of non-null values (null breaks the DataGridPro component)
    const filteredDetailPanelExpandedRowIds = detailPanelExpandedRowIds.filter(
      (id) => id !== null,
    );

    const shittyIsLoading = Boolean(
      isLoading === true ||
        loading === true ||
        loading === LOADING_STATE.LOADING,
    );

    return (
      <DataGridPro
        apiRef={apiRef}
        checkboxSelection={checkboxSelection}
        className={className}
        columnVisibilityModel={columnVisibilityModel}
        columns={orderedAndSizedColumns}
        data-testid={testId}
        detailPanelExpandedRowIds={filteredDetailPanelExpandedRowIds}
        disableColumnFilter={disableColumnFilter}
        disableColumnSorting={isFetching}
        filterModel={filterModel}
        headerSelectionCheckboxProps={headerSelectionCheckboxProps}
        initialState={{
          pinnedColumns: getPinnedColumns(),
          ...initialState,
        }}
        loading={shittyIsLoading}
        paginationMode={paginationMode}
        rowHeight={DatagridUtils.ROW_HEIGHT.THIN}
        rowSelectionModel={rowSelectionModel}
        rows={rows}
        slotProps={slotProps}
        slots={slots}
        sortingMode={sortingMode}
        disableRowSelectionOnClick
        pagination
        onColumnVisibilityModelChange={onColumnVisibilityModelChange}
        onColumnWidthChange={onColumnWidthChange}
        onDetailPanelExpandedRowIdsChange={setDetailPanelExpandedRowIds}
        onFilterModelChange={onFilterModelChange}
        onPinnedColumnsChange={onPinnedColumnsChange}
        onRowClick={onRowClickHandler}
        onRowSelectionModelChange={onRowSelectionModelChange}
        onStateChange={onStateChange}
        {...hiddenHeaderProps}
        {...otherProps}
      />
    );
  },
);

BasicTable.displayName = 'BasicTable';
